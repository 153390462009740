module.exports = {
  en_US: {
    label: {
      lubricationWayCode: 'lubricationWayCode',
      lubricationWay: 'lubricationWay',
      lubricationWayTable: 'lubricationWayTable'
    },
    validate: {
      placeholderForlubricationWay: 'place entry lubricationWay'
    }
  },

  zh_CN: {
    label: {
      lubricationWayCode: '润滑方式编码',
      lubricationWay: '润滑方式',
      lubricationWayTable: '润滑方式列表'
    },
    validate: {
      placeholderForlubricationWay: '请输入润滑方式'
    }
  }
};

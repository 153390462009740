/*
 * @Author: Andy
 * @Date: 2019-07-08 09:12:21
 * @LastEditors: Andy
 * @LastEditTime: 2019-08-22 19:28:44
 */
import { getAuthData, getLocale } from '~/util/session';
import { hasPanesSelector } from './selector';
import * as types from './types';
import { key } from '.';

export const findMenus = (type = 1) => async ({ api, dispatch }) => {
  try {
    if (getAuthData()) {
      const data = await api.get(`/auth/module/getModuleListWithModuleButtonListByUserId/${getAuthData().id}?platform=${type}`);
      dispatch({
        type: types.FIND_MENUS,
        data
      });
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const findMenus1 = (type = 1) => async ({ api, dispatch }) => {
  try {
    if (getAuthData()) {
      const data = await api.get(`/auth/module/getModuleListWithModuleButtonListByUserId/${getAuthData().id}?platform=${type}`);
      dispatch({
        type: types.FIND_MENUS1,
        data
      });
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const clearMenus = () => ({ dispatch }) => {
  dispatch({
    type: types.CLEAR_MENUS,
    data: []
  });
};

export const TestMenus = data => async ({ dispatch }) => {
  dispatch({
    type: types.TEST_INIT_TABS,
    data
  });
};

export const createMenu = data => ({ api }) => api.post('/auth/module/createModule', data);

export const updateMenu = data => ({ api }) => api.put('/auth/module/updateModule', data);

export const findModules = parentId => ({ api }) =>
  api.get(`/auth/module/getModuleListByParentModuleId/${parentId}`);

export const deleteModule = id => ({ api }) => api.delete(`/auth/module/deleteModuleById/${id}`);

export const getButtons = id => async ({ api, dispatch }) => {
  try {
    const data = await api.get(`/auth/moduleButton/getModuleButtonListByModuleID/${id}`);
    dispatch({
      type: types.GET_BUTTONS,
      data
    });
  } catch (err) {
    throw new Error(err);
  }
};

export const createButton = data => ({ api }) =>
  api.post('/auth/moduleButton/createModuleButton', data);

export const updateButton = data => ({ api }) =>
  api.put('/auth/moduleButton/updateModuleButton', data);

export const deleteButton = id => ({ api }) =>
  api.delete(`/auth/moduleButton/deleteModuleButtonById/${id}`);

export const initTabs = () => ({ dispatch }) => dispatch({ type: types.INIT_TABS });

export const addTabs = data => ({ dispatch, getState }) => {
  const state = getState();
  const hasPath = state[key].panes.map(item => item.urlAddress).includes(data.urlAddress);
  if (hasPath) {
    dispatch({
      type: types.CHANGE_ACTIVEKEY,
      data
    });
  } else {
    const { panes } = getState().module;
    const locale = getLocale();
    panes.push({
      fullName: locale === 'zh_CN' ? data.fullName : data.englishName,
      urlAddress: data.urlAddress,
      closable: data.closable
    });
    dispatch({
      type: types.ADD_TABS,
      data: panes
    });
  }
};

export const delTabs = urlAddress => ({ dispatch, getState }) => {
  const hasPath = hasPanesSelector(getState(), urlAddress);
  if (hasPath) {
    const { panes } = getState().module;
    const newpanes = panes.filter(item => item.urlAddress !== urlAddress);
    dispatch({
      type: types.DEL_TABS,
      data: newpanes
    });
  }
};

export const collectionMenu = () => async ({ api, dispatch }) => {
  try {
    const data = await api.post('/auth/getCollectionMenu');
    dispatch({
      type: types.GET_COLLECTION_MENU,
      data
    });
  } catch (error) {
    throw new Error(error);
  }
};

export const addFavorite = (moduleId, userId) => ({ api }) =>
  api.put(`/auth/module/addFavorite?moduleId=${moduleId}&userId=${userId}`);

export const deleteFavorite = (moduleId, userId) => ({ api }) =>
  api.delete(`/auth/module/deleteFavorite?moduleId=${moduleId}&userId=${userId}`);

export const reducerFavorite = modules => ({ api, dispatch }) => {
  dispatch({
    type: types.REDUCER_MENUS,
    data: modules
  });
};


export const pageReacordFn = (userName,page) => async ({api})=>{
  const _userName = encodeURIComponent(userName)
  const _page = encodeURIComponent(page)
  await api.post(`http://${__API_HOST__}:8090/CloudWeb/pageInfo.sp?method=savePageInfo&userName=${_userName}&page=${_page}`, {
    _userName,
    _page
  });
}

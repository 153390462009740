import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
// import { injectReducers } from '~/redux/registry';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/observable/zip';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/fromPromise';
import {
  clear
} from '~/util/session';
import {
  notification
} from 'antd';
import { logout } from '~/redux/reducer/session/actions';
const moduleDefaultExport = module => module.default || module;

function esModule(module, forceArray) {
  if (isArray(module)) {
    return module.map(moduleDefaultExport);
  }

  const defualted = moduleDefaultExport(module);
  return forceArray ? [defualted] : defualted;
}

// inspired in https://github.com/luigiplr/react-router-redux-rxjs-code-splitting
export default function asyncRoute(getComponent, getReducers) {
  return class AsyncRoute extends Component {
    static contextTypes = {
      store: PropTypes.shape({
        dispatch: PropTypes.func.isRequired
      })
    };

    static Component = null;
    // static ReducersLoaded = false;

    state = {
      Component: AsyncRoute.Component,
      ReducersLoaded: AsyncRoute.ReducersLoaded || false
    };

    componentWillMount() {
      const { Component, ReducersLoaded } = this.state;
      const shouldLoadReducers = !ReducersLoaded && getReducers;

      if (!Component || shouldLoadReducers) {
        this._componentWillUnmountSubject = new Subject();

        const streams = [
          Component
            ? Observable.of(Component).takeUntil(this._componentWillUnmountSubject)
            : Observable.fromPromise(getComponent())
              .map(esModule)
              .map(Component => {
                AsyncRoute.Component = Component;
                return Component;
              })
              .takeUntil(this._componentWillUnmountSubject)
        ];

        if (shouldLoadReducers) {
          streams.push(
            Observable.fromPromise(getReducers())
              .map(module => esModule(module, true))
              // eslint-disable-next-line
              .map(reducers => {
                // this.context.store.dispatch(injectReducers(reducers));
                AsyncRoute.ReducersLoaded = true;
              })
              .takeUntil(this._componentWillUnmountSubject)
          );
        }

        Observable.zip(...streams).takeUntil(this._componentWillUnmountSubject).subscribe(([
          Component
        ]) => {
          if (this._mounted) {
            this.setState({ Component });
          } else {
            this.state.Component = Component;
          }

          this._componentWillUnmountSubject.unsubscribe();
        });
      }
    }

    componentDidMount() { 
      // if (!__IS_DEV__) {
      //   window.onbeforeunload = () => {
      //     clear();
      //   }; 
      // } 
      // this._mounted = true;

      // 刷新不退出
      // if (!this.props.location.key) {
      //   clear();
      //   window.location.href = logout;
      // } 

      // window.onbeforeunload = () => {
      //   console.log(this.props.location.key);
      //   if (!this.props.location.key) {
      //     clear();
      //   }
      // }; 

      // 最开始版本
      this._mounted = true;
    }

    componentWillUnmount() {
      if (this._componentWillUnmountSubject && !this._componentWillUnmountSubject.closed) {
        this._componentWillUnmountSubject.next();
        this._componentWillUnmountSubject.unsubscribe();
      }
    }

    render() {
      const { Component } = this.state;
      return Component ? <Component {...this.props} /> : null;
    }
  };
}

module.exports = {
  en_US: {
    label: {
      maintainItemCode: 'maintainItemCode',
      maintainItemName: 'maintainItemName',
      sparePartSelect: 'sparePartSelect',
      sparePartTable: 'sparePartTable',
      lubSelect: 'lubSelect',
      lubTable: 'lubTable',
      equipmentPosition: 'MaintainPosition',
      equipmentWork: 'MaintainWork',
      isHalt: 'isHalt',
      timeNeeded: 'timeNeeded(min)',
      sparePart: 'sparePart',
      lub: 'lub',
      selectsparePart: 'select SparePart',
      selectlub: 'select Lub',
      enable: 'enable',
      remark: 'remark',
      upload: 'upload image',
      titleAdd: 'Create > select part',
      titleEdit: 'Edit > select part',
      titleAddLub: 'Create > select lub',
      titleEditLub: 'Edit > select lub',
      titleAddInspect: 'Create > select Inspect',
      titleEditInspect: 'Edit > selectInspect'
    },
    validate: {
      placeholderForequipmentPosition: 'please entry MaintainPosition',
      placeholderForequipmentWork: 'please entry MaintainWork',
      placeholderFortimeNeeded: 'please entry time needed',
      placeholderForremark: 'please entry remark',
      placeholderFormaintainItemCode: 'please entry maintainItemCode',
      placeholderFormaintainItemName: 'please entry maintainItemName'
    }
  },

  zh_CN: {
    label: {
      maintainItemCode: '保养项目编码',
      maintainItemName: '保养项目名称',
      sparePartSelect: '备件选择',
      sparePartTable: '备件列表',
      lub: '油品',
      selectlub: '选择油品',
      lubSelect: '油品选择',
      lubTable: '油品列表',
      equipmentPosition: '保养部位',
      equipmentWork: '保养工作',
      isHalt: '是否停机',
      timeNeeded: '所需工时(分钟)',
      sparePart: '备件',
      selectsparePart: '选择备件',
      enable: '是否启用',
      remark: '备注',
      upload: '上传图片',
      titleAdd: '新增 > 选择备件',
      titleEdit: '修改 > 选择备件',
      titleAddLub: '新增 > 选择油品',
      titleEditLub: '修改 > 选择油品',
      titleAddInspect: '新增 > 选择点巡检内容',
      titleEditInspect: '修改 > 选择点巡检内容'
      
    },
    validate: {
      placeholderForequipmentPosition: '请输入保养部位',
      placeholderForequipmentWork: '请输入保养工作',
      placeholderFortimeNeeded: '请输入所需工时',
      placeholderForremark: '请输入备注',
      placeholderFormaintainItemCode: '请输入保养项目编码',
      placeholderFormaintainItemName: '请输入保养项目名称'
    }
  }
};

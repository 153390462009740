module.exports = {
  en_US: {
    label: {
      planCode: 'planCode',
      areaCell: 'Area/Cell',
      materialCode: 'materialCode',
      eigenvalue: 'eigenvalue',
      material: 'material',
      routeName: 'routeName',
      planTime: 'PlanTime',
      planStartTime: 'planStartTime',
      planEndTime: 'planEndTime',
      quantity: 'quantity',
      PlanTable: 'PlanTable',
      issuedQuantity: 'issuedQuantity',
      issuedOk: 'issuedSuccess',
      issuedNoOk: 'issuedFail',
      planIssued: 'PlanIssued',
      issued: 'Issued',
      status: 'PlanStatus',
      listShows: 'ListShows',
      ganShows: 'GanttChartShows',
      issuedCount: 'issuedCount',
      workQuantity: 'WorkQuantity',
    },
    validate: {
      placeholderForplanCode: 'please entry planCode',
      placeholderFormaterialCode: 'please entry materialCode',
      placeholderFormaterial: 'please select material',
      placeholderForeigenvalue: 'please entry eigenvalue',
      placeholderForrouteName: 'please select routeName',
      placeholderForplanAreaCell: 'please select Area and Cell',
      placeholderForquantity: 'please entry quantity',
      placeholderForplanplanTime: 'please entry planTime',
      placeholderForplanStartTime: 'please entry planStartTime',
      placeholderForissuedquantity: 'please entry issuedquantity',
      placeholderForplanEndTime: 'please entry planEndTime',
      placeholderForissuedCount: 'please entry issuedCount',
      placeholderForworkQuantity: 'please entry workQuantity',
    },
    btn: {
      modelDownload: 'TemplateDownload',
      import: 'import'
    }
  },
  zh_CN: {
    label: {
      planCode: '计划编码',
      areaCell: '车间/产线',
      materialCode: '物料编码',
      material: '物料',
      eigenvalue: '特征值',
      routeName: '工艺路径',
      planStartTime: '计划开始时间',
      planEndTime: '计划结束时间',
      planTime: '计划时间',
      PlanTable: '计划列表',
      quantity: '计划数量',
      planIssued: '计划下发',
      issuedQuantity: '下发数量',
      issuedOk: '下发成功',
      issuedNoOk: '下发失败',
      issued: '下发',
      issuedCount: '下发次数',
      workQuantity: '工单数量',
      status: '计划状态',
      listShows: '列表显示',
      ganShows: '甘特图显示',
    },
    validate: {
      placeholderForplanCode: '请输入计划编码',
      placeholderFormaterialCode: '请输入物料编码',
      placeholderFormaterial: '请选择物料',
      placeholderForeigenvalue: '请输入特征值',
      placeholderForrouteName: '请选择工艺路径',
      placeholderForquantity: '请输入计划数量',
      placeholderForissuedquantity: '请输入下发数量',
      placeholderForplanplanTime: '请选择计划时间',
      placeholderForplanAreaCell: '请选择车间产线',
      placeholderForplanStartTime: '请输入计划开始时间',
      placeholderForplanEndTime: '请输入计划结束时间',
      placeholderForissuedCount: '请输入下发次数',
      placeholderForworkQuantity: '请输入工单数量',
    },
    btn: {
      modelDownload: '模板下载',
      import: '导入'
    }
  }
};

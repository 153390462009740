module.exports = {
  en_US: {
    label: {
      done: 'done',
      uom_description: 'Uom_Description'
    },
    validate: {
      placeholderForuom_id: 'please entry Code',
      placeholderForuom_description: 'please entry description'
    }
  },

  zh_CN: {
    label: {
      done: '是否完成',
      uom_description: '物料单位描述'
    },
    validate: {
      placeholderForuom_id: '请输入物料单位编码',
      placeholderForuom_description: '请输入物料单位描述'
    }
  }
};

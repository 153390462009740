import { get as _get } from "lodash";
import * as types from "./types";

const initialState = {
  list: {}
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.TEST_REDUX:
      return {
        ...state,
        test: action.data
      };
    case types.GET_DATA:
      return {
        ...state,
        fuelledgerList: action.data
      };
    default:
      return state;
  }
};

export const key = "quality";

reducer.reducer = key;

export default reducer;

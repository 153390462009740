module.exports = {
  en_US: {
    label: {
      code: 'EquipmentCode',
      name: 'EquipmentCode',
      type_id: 'type_Code',
      number: 'number',
      type: 'sort',
      manufacturer: 'manufacturer',
      present_time: 'present_time',
      service_time: 'ervice_time',
      operation: 'operation',
      photo: 'photo'
    },
    input: {
      code: 'Please entry the Code',
      name: 'Please entry the Name',
      type_id: 'Please entry the type_id',
      number: 'Please entry the number',
      type: 'Please select the type',
      manufacturer: 'Please entry the manufacturer',
      present_time: 'Please select the present_time',
      service_time: 'Please select the ervice_time'
    },

    action: {},
    validate: {
      placeholderForName: 'equliment Name',
      placeholderForCode: 'equliment Code'
    },
    apply: {
      import: 'import',
      export: 'export',
      extend: 'extend'
    }
  },
  zh_CN: {
    label: {
      code: '设备编码',
      name: '设备名称',
      type_id: '设备型号',
      number: '设备出厂编号',
      type: '设备类型',
      manufacturer: '生产厂家',
      present_time: '设备到厂日期',
      service_time: '设备使用日期',
      operation: '操作',
      photo: '设备图片'
    },
    input: {
      code: '请输入设备编码',
      name: '请输入设备名称',
      type_id: '请输入设备型号',
      number: '请输入设备出厂编号',
      type: '请选择设备类型',
      manufacturer: '请输入生产厂家',
      present_time: '请选择设备到厂日期',
      service_time: '请选择设备使用日期'
    },
    action: {},
    validate: {
      placeholderForName: '设备名称',
      placeholderForCode: '设备编码'
    },
    apply: {
      import: '导入',
      export: '导出',
      extend: '扩展'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      typeForFile_id: 'typeCode',
      typeForFile_Name: 'typeName'
    },
  },

  zh_CN: {
    label: {
      typeForFile_id: '类型编码',
      typeForFile_Name: '类型名称'
    },
  }
};

import * as types from './types';

const initialState = {
  roles: [],
  detail: {}
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_ROLES:
      return {
        ...state,
        roles: action.data
      };
    case types.GET_ROLES_BY_ID:
      return {
        ...state,
        detail: action.data
      };
    default:
      return state;
  }
};

export const key = 'role';

reducer.reducer = key;

export default reducer;

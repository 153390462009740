/**
 * 扩展字段
 */
module.exports = {
  en_US: {
    validate: {
      placeholderForColumn: 'please entry code!',
      placeholderForType: 'please choose type!',
      placeholderForCN: 'please entry name (chinese)!',
      placeholderForEN: 'please entry name (english)!',
      placeholderForwaring: 'Code names can only begin with a letter!',
    },
    label: {
      column: 'code',
      type: 'type',
      nameForCN: 'name(chinese)',
      nameForEN: 'name(english)',
    }
  },
  zh_CN: {
    validate: {
      placeholderForColumn: '请输入编码',
      placeholderForType: '请选择类型',
      placeholderForCN: '请输入名称(中文)',
      placeholderForEN: '请输入名称(英文)',
      placeholderForwaring: '编码只能以字母开头',
      
    },
    label: {
      column: '编码',
      type: '类型',
      nameForCN: '名称(中文)',
      nameForEN: '名称(英文)',
    }
  }
};

import * as types from './types';

const initialState = {
  recordCode: [],
  recordTable: []
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_RECORD_CODE:
      return {
        ...state,
        recordCode: action.data.dataList
      };
      case types.GET_TABLE_BY_CODE:
      return {
        ...state,
        recordTable: action.data.data
      };
      case types.GET_TABLE_FIRING_HEAD:
      return {
        ...state,
        recordTableFiringHead: action.data.data
      };
      case types.GET_TABLE_COAL_PREPARATION:
      return {
        ...state,
        recordTableCoalPreparation: action.data.data
      };
      case types.GET_TABLE_FIRING_MIDDLE:
      return {
        ...state,
        recordTableFiringMiddle: action.data.data
      };
      case types.GET_TABLE_RAW_MILL:
      return {
        ...state,
        recordTableRawMill: action.data.data
      };
      case types.GET_TABLE_WASTE:
      return {
        ...state,
        recordTableWaste: action.data.data
      };
      case types.GET_TABLE_COGENERATION:
        return {
          ...state,
          recordTableCogeneration: action.data.data
        };
      case types.GET_TABLE_CEMENT_MILL:
      return {
        ...state,
        recordTableCementMill: action.data.data
      };
      case types.GET_SHIFT_FIRING_HEAD:
      return {
        ...state,
        recordShiftFiringHead: action.data.data
      };
      case types.GET_SHIFT_COAL_PREPARATION:
      return {
        ...state,
        recordShiftCoalPreparation: action.data.data
      };
      case types.GET_SHIFT_FIRING_MIDDLE:
      return {
        ...state,
        recordShiftFiringMiddle: action.data.data
      };
      case types.GET_SHIFT_RAW_MILL:
      return {
        ...state,
        recordShiftRawMill: action.data.data
      };
      case types.GET_SHIFT_WASTE:
        return {
          ...state,
          recordShiftWaste: action.data.data
        };
        case types.GET_SHIFT_COGENERATION:
          return {
            ...state,
            recordShiftCogeneration: action.data.data
          };
      case types.GET_SHIFT_CEMENT_MILL:
      return {
        ...state,
        recordShiftCementMill: action.data.data
      };
      case types.GET_STATISTIC_COAL_PREPARATION:
      return {
        ...state,
        recordStatisticCoalPreparation: action.data
      };
      case types.GET_STATISTIC_FIRING_MIDDLE:
      return {
        ...state,
        recordStatisticFiringMiddle: action.data
      };
      case types.GET_STATISTIC_RAW_MILL:
      return {
        ...state,
        recordStatisticRawMill: action.data
      };
      case types.GET_STATISTIC_CEMENT_MILL:
      return {
        ...state,
        recordStatisticCementMill: action.data
      };
      case types.GET_ALL_NAME: 
      return {
        ...state,
        getAllName: action.data
      };
    default:
      return state;
  }
};

export const key = 'RuntimeRecord';

reducer.reducer = key;

export default reducer;

import * as types from './types';
const initialState = {
  shiftList: [{ id: 1 }],
  areaList: [],
  cellList: [],
  userList: [],
  crewList: [],
  crewShiftList: {}
};
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_SHIFTLIST:
      return {
        ...state,
        shiftList: action.data
      };
    case types.GET_CREWLIST:
      return {
        ...state,
        crewList: action.data
      };
    case types.GET_CREWSHIFT:
      return {
        ...state,
        crewShiftList: action.data
      };        
    case types.GET_AREALIST:
      return {
        ...state,
        areaList: action.data
      }; 
    case types.GET_CELLLIST:
      return {
        ...state,
        cellList: action.data
      }; 
    case types.GET_ALLUSERS:
      return {
        ...state,
        userList: action.data
      };     
    default:
      return state;
  }
};

export const key = 'schedule';

reducer.reducer = key;

export default reducer;

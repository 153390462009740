export const GET_SHFITREPORTKILN = 'productionReport/GET_SHFITREPORTKILN';
export const GET_SHFITREPORTRAWMILL = 'productionReport/GET_SHFITREPORTRAWMILL';
export const GET_SHFITREPORTCOALMILL = 'productionReport/GET_SHFITREPORTCOALMILL';
export const GET_PRODPRODUCTOTALINFO = 'productionReport/GET_PRODPRODUCTOTALINFO';
export const GET_PRODSTOPINFO = 'productionReport/GET_PRODSTOPINFO';
export const GET_PRODRAWMILLRATIO_BY_MONTHYEAR = 'productionReport/GET_PRODRAWMILLRATIO_BY_MONTHYEAR';
export const GET_CEMENTMILLRATIO_BY_MONTHYEAR = 'productionReport/GET_CEMENTMILLRATIO_BY_MONTHYEAR';
export const GET_PRORATESPERIOD_BY_MONTHYAER = 'productionReport/GET_PRORATESPERIOD_BY_MONTHYAER';
export const GET_PRODPOWERGENSATISTICS_BY_MONTHYEAR = 'productionReport/GET_PRODPOWERGENSATISTICS_BY_MONTHYEAR';
export const GET_PRODMONTHYEAR_BY_PROCESSCHART = 'productionReport/GET_PRODMONTHYEAR_BY_PROCESSCHART';
export const GET_PROPMONITORCHART = 'productionReport/GET_PROPMONITORCHART';
export const GET_PROPMONITORCHART2 = 'productionReport/GET_PROPMONITORCHART2';
export const GETTONNEELECTRICOAST = 'productionReport/GETTONNEELECTRICOAST';

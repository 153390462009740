module.exports = {
  en_US: {
    validate: {
      wordOrderNumber: 'please entry orderNumber!',
      materialCode: 'please entry matrialCode!'
    },
    label: {
      wordOrderNumber: 'orderNumber',
      workOrder: 'workOrder',
      workOrderState: 'workState',
      planNumber: 'planNumber',
      orderList: 'orderList',
      planTime: 'planTime',
      createTime: 'createTime',
      planStartTime: 'planStartTime',
      planEndTime: 'planEndTime',
      StartTime: 'startTime',
      EndTime: 'endTime',
      type: 'OrderStatus',
      timeArea: 'time Area',
      todayWorkOrderTotal: 'today total',
      todayWorkOrderFinish: 'today finished',
      todayWorkOrderNeeDFinish: 'today need do',
      workStatus: 'workStatus',
      detail: 'detail',
      processDetail: 'processDetail',
      workOrderDetail: 'workOrderDetail',
      createOrder: 'Order Create',
      equlimentChoose: 'equlimentChoose',
      operateParams: 'operateParams',
      inputMaterial: 'inputMaterial',
      outputMaterial: 'outputMaterial',
      workGuide: 'workGuide',
      workOrderDetails: 'workOrderDetails',
      bomDetails: 'bomDetails',
      processTimeLine: 'processTimeLine',
      processAnalysis: 'processAnalysis',
      processExcuteStep: 'processExcuteStep',
      serialName: 'serialName'
    },
    action: {
      create: 'create',
      delete: 'delete',
      modify: 'modify',
      copy: 'copy',
      extend: 'extend',
      handleOut: 'handleOut',
      revoke: 'revoke',
      scrap: 'scrap',
      report: 'report',
      ready: 'ready',
      start: 'start',
      break: 'break',
      resume: 'resume',
      end: 'end',
      switch: 'switch',
      chooseArea: 'chooseArea',
      chooseCell: 'chooseCell',
      chooseEquliment: 'chooseEquliment',
      number: 'number'
    }
  },
  zh_CN: {
    validate: {
      wordOrderNumber: '请输入工单编号!',
      materialCode: '请输入物料编码!'
    },
    label: {
      wordOrderNumber: '工单编号',
      workOrder: '工序编号',
      workOrderState: '工序状态',
      planNumber: '计划编号',
      orderList: '工单列表',
      planTime: '计划时间',
      createTime: '创建时间',
      planStartTime: '计划开始时间',
      planEndTime: '计划完成时间',
      StartTime: '开始时间',
      EndTime: '结束时间',
      timeArea: '时间范围',
      type: '工单状态',
      todayWorkOrderTotal: '工单总数',
      todayWorkOrderFinish: '今日已完成',
      todayWorkOrderNeeDFinish: '今日还需完成',
      workStatus: '执行状态',
      detail: '详情',
      processDetail: '工序详情',
      workOrderDetail: '工单详情',
      createOrder: '工单的创建',
      equlimentChoose: '设备选择',
      operateParams: '工艺参数',
      inputMaterial: '投入物料',
      outputMaterial: '产出物料',
      workGuide: '工作指南',
      workOrderDetails: '工单详情概览',
      bomDetails: 'bom详情概览',
      processTimeLine: '工序时间轴',
      processAnalysis: '工序执行分析',
      processExcuteStep: '工序执行顺序号',
      serialName: '流水号',
      number: '计划数量'
    },
    action: {
      create: '创建',
      delete: '删除',
      modify: '修改',
      copy: '复制',
      extend: '扩展',
      handleOut: '下发',
      revoke: '撤销',
      scrap: '报废',
      report: '报工',
      ready: '就绪',
      start: '开始',
      break: '暂停',
      resume: '继续',
      end: '结束',
      switch: '切换',
      chooseArea: '选择车间',
      chooseCell: '选择产线',
      chooseEquliment: '选择设备'  
    }
  }
};

module.exports = {
  en_US: {
    label: {
      Step: 'Step information',
      ProcessStep: 'Process Step',
      StepCard: 'Step Card',
      Stepsetting: 'Stepsetting',
      Stepsettingassociate: 'Step configuration association',
      material: 'material',
      file: 'file',
      equipment: 'equipment',
      parameter: 'parameter',
      ProcessoperationName: 'ProcessOperationName',
      ProcessStepName: 'ProcessStepName',
      AddProcessStep: 'AddProcessStep',
      AddBindings: 'Add Bindings',
      details: 'Details',
      ProcessStepCode: 'ProcessStepCode',
      ProcessStepName1: 'ProcessStepName',
      ProcessStepOrder: 'ProcessStepOrder',
      ProcessStepType: 'ProcessStepType',
      Routing: 'Routing',
      ProcessOperation: 'ProcessOperation',
      ProcessCode: 'ProcessCode',
      ProcessName: 'ProcessName',
      RouteCode: 'RouteCode',
      RouteName: 'RouteName',
      RouteVersion: 'RouteVersion',
      OprationRoute: 'Opration/Route',
      information: 'Subordinate to the information'
    },
    input: {
      placeholderForProcessStepCode: 'please entry ProcessStepCode',
      placeholderForProcessStepName1: 'please entry ProcessStepName',
      placeholderForProcessStepOrder: 'please entry ProcessStepOrder',
      placeholderForProcessStepType: 'please entry ProcessStepType',
      placeholderForRouteCode: 'please entry RouteCode',
      placeholderForRouteName: 'please entry RouteName',
      placeholderForRouteVersion: 'please entry RouteVersion',
      placeholderForVersion: 'please entry RouteVersion',
      copyForVersion: 'copy RouteVersion'
    }
  },

  zh_CN: {
    label: {
      Step: '步骤信息',
      ProcessStep: '工艺步骤',
      StepCard: '步骤卡片',
      Stepsetting: '步骤配置',
      Stepsettingassociate: '步骤配置关联',
      material: '物料',
      file: '文件',
      equipment: '设备',
      parameter: '参数',
      ProcessoperationName: '工艺操作名称',
      ProcessStepName: '工艺路线名称',
      AddProcessStep: '新增工艺步骤',
      AddBindings: '添加绑定',
      details: '查看绑定',
      ProcessStepCode: '工艺步骤编码',
      ProcessStepName1: '工艺步骤名称',
      ProcessStepOrder: '工艺步骤顺序号',
      ProcessStepType: '工艺步骤类型',
      Routing: '工艺路线',
      ProcessOperation: '工艺操作',
      ProcessCode: '工艺编码',
      ProcessName: '工艺名称',
      RouteCode: '工艺路线编码',
      RouteName: '工艺路线名称',
      RouteVersion: '工艺路线版本',
      OprationRoute: '工艺操作/工艺路线',
      information: '所属信息'
    },
    input: {
      placeholderForProcessStepCode: '请输入工艺步骤编码',
      placeholderForProcessStepName1: '请输入工艺步骤名称',
      placeholderForProcessStepOrder: '请输入工艺步骤顺序号',
      placeholderForProcessStepType: '请输入工艺路线版本',
      placeholderForRouteCode: '请输入工艺路线编码',
      placeholderForRouteName: '请输入工艺路线名称',
      placeholderForRouteVersion: '请输入工艺路线版本',
      placeholderForVersion: '复制Route',
      copyForVersion: '请输入新的版本号'
    }
  }
};

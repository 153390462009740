const version = require('../../package.json');
const login = require('./login').zh_CN;
const message = require('./message').zh_CN;
const menu = require('./sysManager/menu').zh_CN;
const location = require('./sysManager/location').zh_CN;
const user = require('./sysManager/user').zh_CN;
const role = require('./sysManager/role').zh_CN;
const equipment = require('./sysManager/equipment').zh_CN;
const equipmentType = require('./sysManager/equipmentType').zh_CN;
const materialType = require('./sysManager/materialType').zh_CN;
const materialUom = require('./sysManager/materialUom').zh_CN;
const extend = require('./components/extends').zh_CN;
const bom = require('./sysManager/bom').zh_CN;
const parameter = require('./sysManager/parameter').zh_CN;
const material = require('./sysManager/material').zh_CN;
const fileType = require('./sysManager/fileType').zh_CN;
const file = require('./sysManager/file').zh_CN;
const log = require('./sysManager/log').zh_CN;
const operation = require('./sysManager/operation').zh_CN;
const schedule = require('./sysManager/schedule').zh_CN;
const schedulement = require('./sysManager/schedulement').zh_CN;
const route = require('./sysManager/route').zh_CN;
const timer = require('./sysManager/timer').zh_CN;
const workOrder = require('./sysManager/workOrder').zh_CN;
const ProductionPlan = require('./sysManager/ProductionPlan').zh_CN;
const sparePartAnalysis = require('./sysManager/sparePart/sparePartAnalysis').zh_CN;
const sparePartInfo = require('./sysManager/sparePart/sparePartInfo').zh_CN;
const sparePartType = require('./sysManager/sparePart/sparePartType').zh_CN;
const sparePartWarehouse = require('./sysManager/sparePart/sparePartWarehouse').zh_CN;
const sparePartUnit = require('./sysManager/sparePart/sparePartUnit').zh_CN;
const sparePartInStorage = require('./sysManager/sparePart/sparePartInStorage').zh_CN;
const SparePartInventory = require('./sysManager/sparePart/SparePartInventory').zh_CN;
const equipmentFault = require('./sysManager/EquipmentManage/equipmentFault').zh_CN;
const maintenanceCrew = require('./sysManager/EquipmentManage/maintenanceCrew').zh_CN;
const maintenanceProject = require('./sysManager/EquipmentManage/equipmentMaintain/maintenanceProject').zh_CN;
const maintenanceContent = require('./sysManager/EquipmentManage/equipmentMaintain/maintenanceContent').zh_CN;
const maintenancePlan = require('./sysManager/EquipmentManage/equipmentMaintain/maintenancePlan').zh_CN;
const lubricationProject = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationProject').zh_CN;
const lubricationContent = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationContent').zh_CN;
const lubricationWay = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationWay').zh_CN;
const lubricationPlan = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationPlan').zh_CN;
const inspectItemProject = require('./sysManager/EquipmentManage/equipmentInspectItem/inspectItemProject').zh_CN;
const inspectItemContent = require('./sysManager/EquipmentManage/equipmentInspectItem/inspectItemContent').zh_CN;
const inspectItemPlan = require('./sysManager/EquipmentManage/equipmentInspectItem/inspectItemPlan').zh_CN;
const lubType = require('./sysManager/lub/lubType').zh_CN;
const lubInfo = require('./sysManager/lub/lubInfo').zh_CN;
const lubInventory = require('./sysManager/lub/lubInventory').zh_CN;
const equipmentMaintenance = require('./sysManager/EquipmentManage/equipmentMaintenance').zh_CN;
const task = require('./sysManager/task').zh_CN;
const cutterInventory = require('./sysManager/cutter/inventory').zh_CN;
const cutterInfo = require('./sysManager/cutter/info').zh_CN;
const equipmentCutter = require('./sysManager/cutter/equipmentCutter').zh_CN;

const department = require('./sysManager/department').zh_CN;
const global = require('./sysManager/global').zh_CN;

module.exports = {
  app: {
    footer: 'Designed By H VISIONS',
    logout: '退出',
    home: '主页',
    personal_information: '个人信息',
    modify_password: '修改密码'
  },
  login,
  message,
  menu,
  user,
  role,
  equipment,
  extend,
  material,
  bom,
  equipmentType,
  materialType,
  materialUom,
  location,
  parameter,
  fileType,
  file,
  log,
  operation,
  schedule,
  schedulement,
  route,
  workOrder,
  ProductionPlan,
  sparePartInfo,
  sparePartAnalysis,
  sparePartType,
  sparePartWarehouse,
  sparePartUnit,
  timer,
  SparePartInventory,
  equipmentFault,
  maintenanceProject,
  maintenanceContent,
  maintenancePlan,
  lubricationProject,
  lubricationContent,
  lubricationPlan,
  inspectItemProject,
  inspectItemContent,
  inspectItemPlan,
  lubType,
  lubInfo,
  lubInventory,
  sparePartInStorage,
  maintenanceCrew,
  equipmentMaintenance,
  task,
  lubricationWay,
  department,
  cutterInventory,
  cutterInfo,
  equipmentCutter,
  global
};

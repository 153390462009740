module.exports = {
  en_US: {
    label: {
      encode: 'code',
      roleName: 'role name',
      operate: 'operate',
      createTime: 'create time',
      description: 'description',
      roleManage: 'role manage',
      authManage: 'auth manage',
      roleTable: 'RoleTable',
    },
    action: {
      modify: 'modify',
      auth: 'author',
      delete: 'delete',
      newAdd: 'new add',
      delSuccess: 'delete success',
      delError: 'delete error',
      modifySuccess: 'modify suucess',
      modifyError: 'modify error',
      addSuccess: 'add success',
      addError: 'add error',
      authSuccess: 'author success',
      authError: 'author error',
      modifyRole: 'modifyRole',
      newAddRole: 'newAddRole',
      save: 'save'
    },
    validate: {
      placeholderForEncode: 'please entry code',
      placeholderForRoleName: 'please entry role name',
      placeholderForDescription: 'please entry description',
    },
  },
  zh_CN: {
    label: {
      encode: '角色编码',
      roleName: '角色名称',
      roleTable: '角色列表',
      operate: '操作',
      createTime: '创建时间',
      description: '描述',
      roleManage: '角色管理',
      authManage: '权限管理'
    },
    action: {
      modify: '修改',
      auth: '权限',
      delete: '删除',
      newAdd: '新增',
      deleteSuccess: '删除成功',
      deleteError: '删除失败',
      modifySuccess: '修改成功',
      modifyError: '修改失败',
      addSuccess: '增加成功',
      addError: '增加失败',
      authSuccess: '授权成功',
      authError: '授权失败',
      modifyRole: '修改角色',
      newAddRole: '新增角色',
      save: '保存'
    },
    validate: {
      placeholderForEncode: '请输入角色编码',
      placeholderForRoleName: '请输入角色名称',
      placeholderForDescription: '请输入描述',
    },
  }
};

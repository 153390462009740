module.exports = {
  en_US: {
    label: {
      shift_management: 'ShiftManagement',
      crew_management: 'CrewManagement',
      shiftName: 'shiftName',
      shiftCode: 'shiftCode',
      startTime: 'startTime',
      endTime: 'endTime',
      isEnable: 'isEnable',
      workshop: 'workshop',
      line: 'Line',
      crewName: 'crewName',
      crewCode: 'crewCode',
      updateTime: 'updateTime',
      options: 'options'
    },
    validate: {
      placeholderForShiftName: 'please entry name',
      placeholderForShiftCode: 'please entry code',
      placeholderForWorkShop: 'please entry workshop',
      placeholderForLine: 'please entry line',
      placeholderForStartTime: 'please entry startTime',
      placeholderForEndTime: 'please entry endTime',
      placeholderForCrewCode: 'please entry crewcode',
      placeholderForCrewName: 'please entry crewname',
    },
  },

  zh_CN: {
    label: {
      shift_management: '班次管理',
      crew_management: '班组管理',
      shiftName: '班次名称',
      shiftCode: '班次编码',
      startTime: '开始时间',
      endTime: '结束时间',
      isEnable: '是否启用',
      workshop: '车间',
      line: '产线',
      crewName: '班组名称',
      crewCode: '班组编号',
      updateTime: '上次更新时间',
      options: '操作',
    },
    validate: {
      placeholderForShiftName: '输入班次名称',
      placeholderForShiftCode: '输入班次编号',
      placeholderForCrewCode: '输入班组编号',
      placeholderForCrewName: '输入班组名称',
      placeholderForWorkShop: '请选择车间类型',
      placeholderForLine: '请选择产线',
      placeholderForStartTime: '请选择开始时间',
      placeholderForEndTime: '请选择结束时间'
    },
  }
};

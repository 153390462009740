module.exports = {
  en_US: {
    label: {
      faultCode: 'faultCode ',
      faultName: 'faultName',
      faultClassId: 'faultClassId',
      description: 'fault phenomenon',
      faultClassName: 'faultClassName',
      reasonCode: 'reasonCode',
      reason: 'reason',
      faultId: 'fault',
      solutionCode: 'solutionCode',
      solutionName: 'solutionName',
      solution: 'solution',
      reasonId: 'reason',
      bind: 'Binding device type',
      equipmenttype: 'Select equipmenttype'
    },
    validate: {
      placeholderForfaultCode: 'please entry faultCode',
      placeholderForfaultName: 'please entry faultName',
      placeholderFordescription: 'please entry fault phenomenon',
      placeholderForfaultClassName: 'please entry faultClassName',
      placeholderForreasonCode: 'please entry reasonCode',
      placeholderForreason: 'please entry reason',
      placeholderForsolutionCode: 'please entry solutionCode',
      placeholderForsolutionName: 'please entry solutionName',
      placeholderForsolution: 'please entry solution',
      placeholderForequipmenttype: 'please select equipmenttype',
    }
  },

  zh_CN: {
    label: {
      faultCode: '故障编码 ',
      faultName: '故障名称',
      faultClassId: '故障类别',
      description: '故障现象',
      faultClassName: '故障类别名称',
      reasonCode: '故障原因编码',
      reason: '故障原因',
      faultId: '所属故障',
      solutionCode: '解决方案编码',
      solutionName: '解决方案名称',
      solution: '解决步骤',
      reasonId: '所属故障原因',
      bind: '绑定设备类型',
      equipmenttype: '选择设备类型'
    },
    validate: {
      placeholderForfaultCode: '请输入故障编码',
      placeholderForfaultName: '请输入故障名称',
      placeholderFordescription: '请输入故障现象',
      placeholderForfaultClassName: '请输入故障类别名称',
      placeholderForreasonCode: '请输入故障原因编码',
      placeholderForreason: '请输入故障原因',
      placeholderForsolutionCode: '请输入解决方案编码',
      placeholderForsolutionName: '请输入解决方案名称',
      placeholderForsolution: '请输入解决步骤',
      placeholderForequipmenttype: '请选择设备类型',
    }
  }
};

module.exports = {
  en_US: {
    label: {
      teamCode: 'teamCode ',
      teamName: 'teamName',
      teamTable: 'teamTable',
      
    },
    validate: {
      placeholderForteamName: 'please entry teamName ',
      placeholderForteamCode: 'please entry teamCode'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      teamName: '班组名称',
      teamCode: '班组编号',
      teamTable: '班组列表'
    },
    validate: {
      placeholderForteamCode: '请输入班组编码',
      placeholderForteamName: '请输入班组名称'
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      inspectPlanNum: 'inspectPlanNum',
      inspectPlanName: 'inspectPlanName',
      inspectPlanConditionName: 'planStatus',
      inspectContent: 'inspectContent',
      timer: 'timer'
    },
    validate: {
      placeholderForinspectPlanNum: 'please entry inspectPlanNum',
      placeholderForinspectPlanName: 'please entry inspectPlanName',
      placeholderForinspectContent: 'please select inspectContent'
    }
  },

  zh_CN: {
    label: {
      inspectPlanNum: '点巡检计划编码',
      inspectPlanName: '点巡检计划名称',
      inspectPlanConditionName: '计划状态',
      inspectContent: '点巡检内容',
      timer: '周期配置'
    },
    validate: {
      placeholderForinspectPlanNum: '请输入点巡检计划编码',
      placeholderForinspectPlanName: '请输入点巡检计划名称',
      placeholderForinspectContent: '请选择点巡检内容'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      column: 'column',
      location: 'location',
      logCaptureTime: 'CaptureTime',
      logExceptionMessage: 'ExceptionMessage',
      logInvocation: 'Invocation',
      logModular: 'Modular',
      logParameter: 'Parameter',
      logType: 'logType',
      deleteSuccess: 'deleteSuccess',
      deleteError: 'deleteError',
      options: 'options'
    },
    input: {
      logInvocation: 'Please entry the logInvocation',
      logModular: 'Please entry the logModular',
      logType: 'Please select the logType',
    },
    action: {
      save: 'save',
      cancel: 'cancel',
      modify: 'modify'
    },
    delete: {
      confirmTitle: 'Confirm to delete ?',
    }
  },
  zh_CN: {
    label: {
      column: '列名',
      location: '调用url和路径',
      logCaptureTime: '调用时间',
      logExceptionMessage: '异常信息',
      logInvocation: '调用人',
      logModular: '模块',
      logParameter: '传入参数',
      logType: '日志类型', 
      deleteSuccess: '删除成功',
      deleteError: '删除失败',
      options: '操作'
    },
    input: {
      logInvocation: '请输入调用人',
      logModular: '请输入模块',
      logType: '请选择日志类型',
    },
    action: {       
      save: '保存',
      cancel: '取消',
      modify: '修改'
    },
    delete: {
      confirmTitle: '确认删除?',
    }
  }
};

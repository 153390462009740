/*
 * @Author: Andy
 * @Date: 2019-07-08 09:12:21
 * @LastEditors: Andy
 * @LastEditTime: 2019-08-26 12:18:46
 */
import { createSelector } from 'reselect';
import { getAuthData } from '~/util/session';
import { isEmpty, concat } from 'lodash';
import { key } from '.';

export const modules = state => {
  const module = state[key].modules;
  const newModule = [...module];
  newModule.forEach((value, index) => {
    if (getAuthData().sysModuleDTO && getAuthData().sysModuleDTO.urlAddress === value.urlAddress) {
      newModule[index].closable = false;
    } else {
      newModule[index].closable = true;
    }
  });
  return state[key].modules || [];
};

export const modules1 = state => {
  const module = state[key].modules1;
  const newModule = [...module];
  newModule.forEach((value, index) => {
    if (getAuthData().sysModuleDTO && getAuthData().sysModuleDTO.urlAddress === value.urlAddress) {
      newModule[index].closable = false;
    } else {
      newModule[index].closable = true;
    }
  });
  return state[key].modules1 || [];
};

export const buttons = state => state[key].buttons;
export const panesSelector = state => state[key].panes;
export const activeKey = state => state[key].activeKey;

export const feature = (_, { pathname }) => pathname;

const formatMenus = modules =>
  modules.filter(p => {
    const children = modules.filter(c => c.parentId === p.id);
    p.children = !isEmpty(children) ? children : undefined;
    return p.parentId === 0;
  });

// 菜单树
export const menusSelector = createSelector(modules, formatMenus);
export const menusSelector1 = createSelector(modules1, formatMenus);

// export const menusSelectorTest = state => {
//   const module = state[key].modules;
//   const newModule = [...module];
//   newModule.forEach((value, index) => {
//     if (getAuthData().sysModuleDTO && getAuthData().sysModuleDTO.urlAddress === value.urlAddress) {
//       newModule[index].closable = false;
//     } else {
//       newModule[index].closable = true;
//     }
//   });
//   return state[key].modules || [];
// };

export const routePermissions = createSelector(
  modules,
  list => list.filter(l => l.type === 20)
);

export const permissionsSelector = createSelector(
  routePermissions,
  feature,
  (list, path) => {
    const module = list.find(l => l.urlAddress === path);
    return !isEmpty(module) ? module.buttons : [];
  }
);

export const listSelector = state => state[key].modules1 || [];

export const getPane = createSelector(
  modules,
  (_, location) => location.pathname,
  (list, p) =>
    list.find(l => {
      if (/^\/link\//.test(p)) {
        // 兼容外部链接菜单 (包含http:// 或者 https://)
        return `/link/${l.fullName}` === p;
      }
      const reg = new RegExp(`${l.urlAddress}/`);
      if (l.urlAddress !== '/' && reg.test(p)) {
        return true;
      }
      return l.urlAddress === p;
    }) || { fullName: '主页', urlAddress: '/', closable: false }
);

export const hasPanesSelector = createSelector(
  panesSelector,
  (_, path) => path,
  (list, p) => list.map(item => item.urlAddress).includes(p)
);

export const collectionMenuSelector = state => state[key].collectionMenu || [];

export const menuLeftListSelector = state => {
  // const filterData = state[key].modules.filter(p => {
  //   const children = state[key].modules.filter(c => c.parentId === p.id);
  //   p.children = !isEmpty(children) ? children : [];
  //   return p.parentId === 0;
  // });
  // return concat([], ...filterData.map(item => item.children.filter(childItem => childItem.favorite)).filter(item => item.length != 0));
  // console.log();
  return state[key].modules.filter(item => item.favorite) || []; // 获取收藏夹列表
};

export const reducerMenuSelector = state => state[key].collectionMenu || [];

import { combineReducers } from "redux";
import session from "./session";
import module from "./module";
import user from "./user";
import role from "./role";
import operation from "./operation";
import file from "./file";
import fileType from './fileType';
import parameter from "./parameter";
import quality from "./quality";
import factorycontrol from "./factorycontrol";
import performanceLog from "./performanceLog";
import performance from './performance';
import home from './home';
import offpeakanalysis from './offpeakanalysis';
import RuntimeRecord from './RuntimeRecord';

import schedule from './schedule';
import schedulement from './schedulement';

import productionReport from "./productionReport";
import measureReport from "./measureReport";
export default combineReducers({
  session,
  module,
  user,
  role,
  operation,
  file,
  parameter,
  quality,
  factorycontrol,
  performanceLog,
  performance,
  productionReport,
  home,
  offpeakanalysis,
  RuntimeRecord,
  schedule,
  schedulement,
  measureReport,
  fileType
});

module.exports = {
  en_US: {
    label: {
      spareCode: 'spareCode',
      spareName: 'spareName',
      batchNumber: 'batchNumber',
      company: 'company',
      supplier: 'supplier',
      company1: 'company',
      spareType: 'spareType',
      unitPrice: 'unitPrice',
      cargoLocation: 'cargoLocation',
      cargoMax: 'cargoMax',
      cargoMin: 'cargoMin',
      spareTable: 'spareTable',
      spareCount: 'spareCount',
      remarks: 'remarks',
      photo: 'photo'
    },
    validate: {
      placeholderForspareCode: 'please entry spareCode',
      placeholderForspareName: 'please entry spareName',
      placeholderForbatchNumber: 'please entry batchNumber',
      placeholderForcompany: 'please entry company',
      placeholderForsupplier: 'please entry supplier',
      placeholderForcargoLocation: 'please entry cargoLocation',
      placeholderForcargoMax: 'please entry cargoMax',
      placeholderForspareType: 'please entry spareType',
      placeholderForcargoMin: 'please entry cargoMin',
      placeholderForunitPrice: 'please entry unitPrice',
      placeholderForremarks: 'please entry remarks'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      spareCode: '备件编码',
      spareName: '备件名称',
      batchNumber: '备件批次号',
      company: '备件单位',
      company1: '备件单位',
      supplier: '备件供应商',
      spareType: '备件类型',
      cargoLocation: '备件库房',
      unitPrice: '备件单价',
      cargoMax: '备件最大库存',
      cargoMin: '备件最小库存',
      spareTable: '备件列表',
      spareCount: '备件数量',
      remarks: '备注',
      photo: '备件图片'
    },
    validate: {
      placeholderForspareCode: '请输入备件编码',
      placeholderForspareName: '请输入备件名称',
      placeholderForbatchNumber: '请输入备件批次号',
      placeholderForcompany: '请选择备件单位',
      placeholderForsupplier: '请输入备件供应商',
      placeholderForspareType: '请选择备件类型',
      placeholderForcargoLocation: '请选择备件库房',
      placeholderForcargoMax: '请输入备件最小库存',
      placeholderForcargoMin: '请输入备件最大库存',
      placeholderForunitPrice: '请输入备件单价',
      placeholderForremarks: '请输入备注',
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

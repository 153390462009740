import { isEmpty } from 'lodash';
import * as types from './types';
import { getFormattedMsg } from '~/i18n';

const initialState = {
  modules: [],
  modules1: [],
  buttons: [],
  panes: [],
  activeKey: '',
  collectionMenu: []
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.FIND_MENUS:
      return {
        ...state,
        modules: action.data || []
      };
    case types.FIND_MENUS1:
      return {
        ...state,
        modules1: action.data || []
      };
    case types.CLEAR_MENUS: 
      return {
        ...state,
        modules: []
      };
    case types.GET_BUTTONS:
      return {
        ...state,
        buttons: action.data || []
      };
    case types.INIT_TABS:
      return {
        ...state,
        panes: [],
        activeKey: ''
      };
    case types.TEST_INIT_TABS:
      return {
        ...state,
        panes: action.data,
        activeKey: ''
      };
    case types.CHANGE_ACTIVEKEY:
      return {
        ...state,
        activeKey: action.data.urlAddress
      };
    case types.GET_COLLECTION_MENU:
      return {
        ...state,
        collectionMenu: action.data
      };
    case types.REDUCER_MENUS: 
      return {
        ...state,
        collectionMenu: action.data
      };
    case types.ADD_TABS:
    case types.DEL_TABS:
      // eslint-disable-next-line
      let panes = action.data;
      // eslint-disable-next-line
      // const hasRoot = state.modules
      //   .filter(item => isEmpty(item.children))
      //   .some(item => item.urlAddress === '/');

      // // eslint-disable-next-line
      // panes = hasRoot
      //   ? action.data
      //   : action.data.map(item => (item.urlAddress !== '/' ? item : { ...item, fullName: '' }));

      return {
        ...state,
        panes
      };
    default:
      return state;
  }
};

export const key = 'module';

reducer.reducer = key;

export default reducer;

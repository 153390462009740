module.exports = {
  en_US: {
    label: {
      lubNumber: 'lubNumber ',
      lubInventory: 'lubInventoryTable',
      lubPart: 'lubPart',
      storehouse: 'storehouse',
      batchNumber: 'batchNumber',
      lubPartType: 'lubPartType',
      lubPartNumber: 'lubPartNumber',
    },
    validate: {
      placeholderFortypeCode: 'please entry lubNumber ',
      placeholderForstorehouse: 'please entry storehouse',
      placeholderForbatchNumber: 'please entry batchNumber',
      placeholderForlubPartType: 'please entry lubPartType',
      placeholderForlubPartNumber: 'please entry lubPartNumber'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      lubNumber: '油品数量',
      lubInventory: '油品库存列表',
      lubPart: '油品',
      batchNumber: '油品批次号',
      storehouse: '油品库房',
      lubPartType: '油品类型',
      lubPartNumber: '油品数量',
    },
    validate: {
      placeholderForlubPart: '请选择油品',
      placeholderForbatchNumber: '请输入油品批次号',
      placeholderForstorehouse: '请选择油品库房',
      placeholderForlubPartType: '请选择油品类型',
      placeholderForlubPartNumber: '请输入油品数量'
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

// 工厂控制层相关操作
export const GET_DATA_FACTORY = "factorycontrol/GET_DATA_FACTORY";
export const ADD_FACTORY_INFO = "factorycontrol/ADD_FACTORY_INFO";
export const EDIT_FACTORY_INFO = "factorycontrol/EDIT_FACTORY_INFO";
export const FACTORY_DELETE_FACTORY_INFO = "factorycontrol/DELETE_FACTORY_INFO";
export const FACTORY_GET_DETAIL_INFO = "factorycontrol/FACTORY_GET_DETAIL_INFO";
// 工序控制层相关操作
export const PRODUCTIONLINE_GET_LINE_OPTIONS = "factorycontrol/PRODUCTIONLINE_GET_LINE_OPTIONS";
export const PRODUCTIONLINE_GET_LINE_DATA = "factorycontrol/PRODUCTIONLINE_GET_LINE_DATA";
export const PRODUCTIONLINE_ADD_LINE_INFO = "factorycontrol/PRODUCTIONLINE_ADD_LINE_INFO";
export const PRODUCTIONLINE_EDIT_LINE_INFO = "factorycontrol/PRODUCTIONLINE_EDIT_LINE_INFO";
export const PRODUCTIONLINE__DELETE_LINE_INFO = "factorycontrol/PRODUCTIONLINE__DELETE_LINE_INFO";
export const PRODUCTIONLINE_GET_DETAIL_INFO = "factorycontrol/PRODUCTIONLINE_GET_DETAIL_INFO";
// 产线控制层相关操作
export const STATION_GET_LINE_OPTIONS = "factorycontrol/STATION_GET_LINE_OPTIONS";
export const STATION_GET_LINE_DATA = "factorycontrol/STATION_GET_LINE_DATA";
export const STATION_ADD_LINE_INFO = "factorycontrol/STATION_ADD_LINE_INFO";
export const STATION_EDIT_LINE_INFO = "factorycontrol/STATION_EDIT_LINE_INFO";
export const STATION__DELETE_LINE_INFO = "factorycontrol/STATION__DELETE_LINE_INFO";
export const STATION_GET_DETAIL_INFO = "factorycontrol/STATION_GET_DETAIL_INFO";
// 车间控制层相关操作
export const AREA_GET_LINE_OPTIONS = "factorycontrol/AREA_GET_LINE_OPTIONS";
export const AREA_GET_LINE_DATA = "factorycontrol/AREA_GET_LINE_DATA";
export const AREA_ADD_LINE_INFO = "factorycontrol/AREA_ADD_LINE_INFO";
export const AREA_EDIT_LINE_INFO = "factorycontrol/AREA_EDIT_LINE_INFO";
export const AREA__DELETE_LINE_INFO = "factorycontrol/AREA__DELETE_LINE_INFO";
export const AREA_GET_DETAIL_INFO = "factorycontrol/AREA_GET_DETAIL_INFO";
// 熟料生产计划相关
export const PRODUCTIONPLAN_GET_OPTIONS = "factorycontrol/PRODUCTIONPLAN_GET_OPTIONS";
export const PRODUCTIONPLAN_GET_TABLE_DATA = "factorycontrol/PRODUCTIONPLAN_GET_TABLE_DATA";
export const PRODUCTIONPLAN_ADD_DATA_INFO = "factorycontrol/PRODUCTIONPLAN_ADD_DATA_INFO";
export const PRODUCTIONPLAN_EDIT_DATA_INFO = "factorycontrol/PRODUCTIONPLAN_EDIT_DATA_INFO";
export const PRODUCTIONPLAN_DELETE_DATA_INFO = "factorycontrol/PRODUCTIONPLAN_DELETE_DATA_INFO";
// 熟料销售计划相关
export const PRODUCTIONSALEPLAN_GET_OPTIONS = "factorycontrol/PRODUCTIONSALEPLAN_GET_OPTIONS";
export const PRODUCTIONSALEPLAN_GET_TABLE_DATA = "factorycontrol/PRODUCTIONSALEPLAN_GET_TABLE_DATA";
export const PRODUCTIONSALEPLAN_ADD_DATA_INFO = "factorycontrol/PRODUCTIONSALEPLAN_ADD_DATA_INFO";
export const PRODUCTIONSALEPLAN_EDIT_DATA_INFO = "factorycontrol/PRODUCTIONSALEPLAN_EDIT_DATA_INFO";
export const PRODUCTIONSALEPLAN_DELETE_DATA_INFO = "factorycontrol/PRODUCTIONSALEPLAN_DELETE_DATA_INFO";
// 原煤价格相关
export const COALMILLPRICE_GET_TABLE_DATA = "factorycontrol/COALMILLPRICE_GET_TABLE_DATA";
export const COALMILLPRICE_ADD_DATA_INFO = "factorycontrol/COALMILLPRICE_ADD_DATA_INFO";
export const COALMILLPRICE_EDIT_DATA_INFO = "factorycontrol/COALMILLPRICE_EDIT_DATA_INFO";
export const COALMILLPRICE_DELETE_DATA_INFO = "factorycontrol/COALMILLPRICE_DELETE_DATA_INFO";
// 辅料生产相关
export const SUPMATERIALSPRODPLAN_GET_PARTS = "factorycontrol/SUPMATERIALSPRODPLAN_GET_PARTS";
export const SUPMATERIALSPRODPLAN_GET_LINES = "factorycontrol/SUPMATERIALSPRODPLAN_GET_LINES";
export const SUPMATERIALSPRODPLAN_GET_TABLE_DATA = "factorycontrol/SUPMATERIALSPRODPLAN_GET_TABLE_DATA";
export const SUPMATERIALSPRODPLAN_ADD_DATA_INFO = "factorycontrol/SUPMATERIALSPRODPLAN_ADD_DATA_INFO";
export const SUPMATERIALSPRODPLAN_EDIT_DATA_INFO = "factorycontrol/SUPMATERIALSPRODPLAN_EDIT_DATA_INFO";
export const SUPMATERIALSPRODPLAN_DELETE_DATA_INFO = "factorycontrol/SUPMATERIALSPRODPLAN_DELETE_DATA_INFO";
// 库位相关
export const STORAGE_GET_TABLE_DATA = "factorycontrol/STORAGE_GET_TABLE_DATA";
export const STORAGE_ADD_DATA_INFO = "factorycontrol/STORAGE_ADD_DATA_INFO";
export const STORAGE_EDIT_DATA_INFO = "factorycontrol/STORAGE_EDIT_DATA_INFO";
export const STORAGE_DELETE_DATA_INFO = "factorycontrol/STORAGE_DELETE_DATA_INFO";
// 烧失量系数
export const CLINKERBURN_GET_TABLE_DATA = "factorycontrol/CLINKERBURN_GET_TABLE_DATA";
export const CLINKERBURN_ADD_DATA_INFO = "factorycontrol/CLINKERBURN_ADD_DATA_INFO";
export const CLINKERBURN_EDIT_DATA_INFO = "factorycontrol/CLINKERBURN_EDIT_DATA_INFO";
export const CLINKERBURN_DELETE_DATA_INFO = "factorycontrol/CLINKERBURN_DELETE_DATA_INFO";
// 进出场料
export const STOCKRECORD_GET_TABLE_DATA = "factorycontrol/STOCKRECORD_GET_TABLE_DATA";
export const STOCKRECORD_ADD_DATA_INFO = "factorycontrol/STOCKRECORD_ADD_DATA_INFO";
// 库存
export const STOCK_GET_OPTIONS = "factorycontrol/STOCK_GET_OPTIONS";
export const STOCK_GET_TABLE_DATA = "factorycontrol/STOCK_GET_TABLE_DATA";
export const Stock_EDIT_DATA_INFO = "factorycontrol/Stock_EDIT_DATA_INFO"
// 盘点
export const INVENTORY_GET_TABLE_DATA = "factorycontrol/INVENTORY_GET_TABLE_DATA";
export const INVENTORY_ADD_DATA_INFO = "factorycontrol/INVENTORY_ADD_DATA_INFO";
// 事故
export const ACCIDENT_GET_OPTIONS = "factorycontrol/ACCIDENT_GET_OPTIONS";
export const ACCIDENT_GET_TABLE_DATA = "factorycontrol/ACCIDENT_GET_TABLE_DATA";
export const ACCIDENT_ADD_DATA_INFO = "factorycontrol/ACCIDENT_ADD_DATA_INFO";
export const ACCIDENT_EDIT_DATA_INFO = "factorycontrol/ACCIDENT_EDIT_DATA_INFO";
export const ACCIDENT_DELETE_DATA_INFO = "factorycontrol/ACCIDENT_DELETE_DATA_INFO";
// 物料管理
export const PART_GET_TABLE_DATA = "factorycontrol/PART_GET_TABLE_DATA";
export const PART_ADD_DATA_INFO = "factorycontrol/PART_ADD_DATA_INFO";
export const PART_EDIT_DATA_INFO = "factorycontrol/PART_EDIT_DATA_INFO";
export const PART_DELETE_DATA_INFO = "factorycontrol/PART_DELETE_DATA_INFO";
// 电价
export const RATES_GET_NAME_OPTIONS = "factorycontrol/RATES_GET_NAME_OPTIONS";
export const RATES_GET_TYPE_OPTIONS = "factorycontrol/RATES_GET_TYPE_OPTIONS";
export const RATES_GET_TABLE_DATA = "factorycontrol/RATES_GET_TABLE_DATA";
export const RATES_ADD_DATA_INFO = "factorycontrol/RATES_ADD_DATA_INFO";
export const RATES_EDIT_DATA_INFO = "factorycontrol/RATES_EDIT_DATA_INFO";
export const RATES_DELETE_DATA_INFO = "factorycontrol/RATES_DELETE_DATA_INFO";
// 能耗参数
export const ENERGY_GET_DRAG_OPTIONS = "factorycontrol/ENERGY_GET_TYPE_OPTIONS";
export const ENERGY_GET_TABLE_DATA = "factorycontrol/ENERGY_GET_TABLE_DATA";
export const ENERGY_ADD_DATA_INFO = "factorycontrol/ENERGY_ADD_DATA_INFO";
export const ENERGY_EDIT_DATA_INFO = "factorycontrol/ENERGY_EDIT_DATA_INFO";
export const ENERGY_DELETE_DATA_INFO = "factorycontrol/ENERGY_DELETE_DATA_INFO";
// 能耗指标
export const ENERGYVALUE_GET_TABLE_DATA = "factorycontrol/ENERGYVALUE_GET_TABLE_DATA";
export const ENERGYVALUE_ADD_DATA_INFO = "factorycontrol/ENERGYVALUE_ADD_DATA_INFO";
export const ENERGYVALUE_EDIT_DATA_INFO = "factorycontrol/ENERGYVALUE_EDIT_DATA_INFO";
export const ENERGYVALUE_DELETE_DATA_INFO = "factorycontrol/ENERGYVALUE_DELETE_DATA_INFO";
// 水消耗量录入
export const WATERCONSUMP_GET_NAME_OPTIONS = "factorycontrol/WATERCONSUM_GET_TYPE_OPTIONS";
export const WATERCONSUMP_GET_TABLE_DATA = "factorycontrol/WATERCONSUM_GET_TABLE_DATA";
export const WATERCONSUMP_ADD_DATA_INFO = "factorycontrol/WATERCONSUM_ADD_DATA_INFO";
export const WATERCONSUMP_EDIT_DATA_INFO = "factorycontrol/WATERCONSUM_EDIT_DATA_INFO";
export const WATERCONSUMP_DELETE_DATA_INFO = "factorycontrol/WATERCONSUM_DELETE_DATA_INFO";
// 检测项目
export const QUALITYITEM_GET_TABLE_DATA = "factorycontrol/QUALITYITEM_GET_TABLE_DATA";
export const QUALITYITEM_ADD_DATA_INFO = "factorycontrol/QUALITYITEM_ADD_DATA_INFO";
export const QUALITYITEM_EDIT_DATA_INFO = "factorycontrol/QUALITYITEM_EDIT_DATA_INFO";
export const QUALITYITEM_DELETE_DATA_INFO = "factorycontrol/QUALITYITEM_DELETE_DATA_INFO";
// 标签管理
export const LABEL_GET_TABLE_DATA = "factorycontrol/LABEL_GET_TABLE_DATA";
export const LABEL_ADD_DATA_INFO = "factorycontrol/LABEL_ADD_DATA_INFO";
export const LABEL_EDIT_DATA_INFO = "factorycontrol/LABEL_EDIT_DATA_INFO";
export const LABEL_DELETE_DATA_INFO = "factorycontrol/LABEL_DELETE_DATA_INFO";
// 停机类型
export const PROCESSSTOPTYPE_GET_TABLE_DATA = "factorycontrol/PROCESSSTOPTYPE_GET_TABLE_DATA";
export const PROCESSSTOPTYPE_ADD_DATA_INFO = "factorycontrol/PROCESSSTOPTYPE_ADD_DATA_INFO";
export const PROCESSSTOPTYPE_EDIT_DATA_INFO = "factorycontrol/PROCESSSTOPTYPE_EDIT_DATA_INFO";
export const PROCESSSTOPTYPE_DELETE_DATA_INFO = "factorycontrol/PROCESSSTOPTYPE_DELETE_DATA_INFO";
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { push as pushState } from 'react-router-redux';
import { addTabs, findMenus, clearMenus } from '~/redux/reducer/module/actions';
import { setAuthData, clear, saveToken, isLoggedIn, getAuthData } from '~/util/session';
import { selectLoginSuccess } from '~/redux/reducer/session/selector';
import { menusSelector } from '~/redux/reducer/module/selector';
import { findIndex, isEmpty } from 'lodash';
import { notification } from 'antd';
class AuthenticatedRoute extends PureComponent {
  async componentDidMount() {
    try {
      await this.props.findMenus();
    } catch (error) {
      console.log(error);
    }
  }

  // 退出登录并且清理缓存
  clearPush = async () => {
    await this.props.pushState('/');
    clear();
    await this.props.clearMenus();
  }

  demofirst = userId => {
    const obj = {
      1610: {
        url: '/workOrder',
        userName: '钱嘉诚',
        fullName: '工单管理',
        parentId: 1102,
        id: 1103
      },
      1604: {
        url: '/material-uom',
        userName: '雷鸣',
        fullName: '单位',
        parentId: 1029,
        id: 1044
      },
      1607: {
        url: '/workExcuteByUser',
        userName: '李强'
      },
      1605: { // TODO
        url: '/schedule',
        userName: '小张',
        fullName: '班次/班组',
      },
      1613: {
        url: '/workExcuteByUser',
        userName: '朱晓飞'
      },
      1608: {
        url: '/location',
        userName: '张子琪'
      },
      1617: {
        url: '/',
        userName: '解飞'
      },
      1616: {
        url: '/equipment',
        userName: '朱小佳'
      },
      1609: {
        url: '/',
        userName: '郭磊'
      }
    };
    
    const id = parseInt(userId.substr(userId.length - 4), 10);  
    if (obj[id]) {
      clear();
      this.props.clearMenus();
      saveToken('fake token');
      addTabs([{ fullName: obj[id].fullName, urlAddress: obj[id].url, closable: false }]);
      setAuthData({ id, userName: obj[id].userName, sysModuleDTO: { urlAddress: obj[id].url }, menuId: obj[id].id, menuParentId: obj[id].parentId });
      return obj[id];
    }
    return { url: '/' };
  }

  renderRoute = props => {
    const { component: Component, loginPath, location, menu } = this.props;
    const userId = location.hash;
    const newMenuArr = [];
    menu.forEach(value => {
      newMenuArr.push(...(value.children || []));
    });

    const newLocation = Object.assign({}, location);
    // 深拷贝一份location, 防止原本的location被篡改

    // 根据正则获取一级路由
    if (newLocation.pathname.replace('/', 'a').indexOf('/') != -1) {
      newLocation.pathname = newLocation.pathname.substr(0, newLocation.pathname.replace('/', 'a').indexOf('/'));
    }
    // 判断路由是否在该用户的权限菜单中
    const flag = !isEmpty(newMenuArr) ? findIndex(newMenuArr, item => item.urlAddress === newLocation.pathname) : 0;

    // 用户登录但是不存在权限以及菜单则跳转登录界面
    if (getAuthData() && !getAuthData().id && flag === -1) {
      this.clearPush();
    }

    if (userId) {
      return <Redirect to={{ pathname: this.demofirst(userId).url }} />;
    }
    
    if (isLoggedIn()) {
      // 用户存在但是用户跳转的菜单不存在, 则跳转至登录页

      if (!isEmpty(menu) && location.pathname !== '/' && flag === -1) {
        // this.clearPush();
      }
      return <Component {...props} />;
    }

    return (
      <Redirect
        to={{
          pathname: loginPath,
          state: { from: props.location }
        }}
      />
    );
  };

  render() {
    const { rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  loginPath: PropTypes.string.isRequired,
  isLogin: PropTypes.bool.isRequired,
  location: PropTypes.object,
  addTabs: PropTypes.func,
};

AuthenticatedRoute.contextTypes = {
  store: PropTypes.object,
  pushState: PropTypes.func,
  findMenus: PropTypes.func,
  menu: PropTypes.array,
  menuTest: PropTypes.array
};

const mapStateToProps = state => ({
  isLogin: selectLoginSuccess(state),
  menu: menusSelector(state)
});

export default withRouter(connect(mapStateToProps, {
  addTabs,
  pushState,
  findMenus,
  clearMenus
})(AuthenticatedRoute));

module.exports = {
  en_US: {
    label: {
      inspectItemCode: 'inspectItemCode',
      inspectItemName: 'inspectItemName',
      inspectPosition: 'inspectPosition',
      inspectWork: 'inspectWork',
      inspectTheoreticalValue: 'TheoreticalValue',
    },
    validate: {
      placeholderForinspectItemCode: 'please entry inspectItemCode',
      placeholderForinspectItemName: 'please entry inspectItemName',
      placeholderForinspectPosition: 'please entry inspectPosition',
      placeholderForinspectWork: 'please entry inspectWork',
      placeholderForinspectTheoreticalValue: 'please entry TheoreticalValue',
    }
  },

  zh_CN: {
    label: {
      inspectItemCode: '点检项目编码',
      inspectItemName: '点检项目名称',
      inspectPosition: '点检项目部位',
      inspectWork: '点检工作',
      inspectTheoreticalValue: '点检理论值',
    },
    validate: {
      placeholderForinspectItemCode: '请输入点检项目编码',
      placeholderForinspectItemName: '请输入点检项目名称',
      placeholderForinspectPosition: '请输入点检项目部位',
      placeholderForinspectWork: '请输入点检工作',
      placeholderForinspectTheoreticalValue: '请输入点检理论值',
    }
  }
};

import { get as _get } from "lodash";
import * as types from "./types";

const initialState = {
  list: {}
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_DATA_FACTORY:
      return {
        ...state,
        factoryData: action.data
      };
    case types.ADD_FACTORY_INFO:
      return {
        ...state,
        addFactoryInfo: action.data.status
      };
    case types.EDIT_FACTORY_INFO:
      return {
        ...state,
        editFactoryInfo: action.data.status
      };
    case types.FACTORY_DELETE_FACTORY_INFO:
      return {
        ...state,
        factoryDeleteFactoryInfo: action.data.status
      };
    case types.FACTORY_GET_DETAIL_INFO:
      return {
        ...state,
        factoryGetDetailInfo: action.data
      };
    // 产线相关操作
    case types.PRODUCTIONLINE_GET_LINE_OPTIONS:
      return {
        ...state,
        productionlineGetOptions: action.data.data1
      };
    case types.PRODUCTIONLINE_GET_LINE_DATA:
      return {
        ...state,
        productionlineGetData: action.data
      };
    case types.PRODUCTIONLINE_ADD_LINE_INFO:
      return {
        ...state,
        productionlineAddInfo: action.data.status
      };
    case types.PRODUCTIONLINE_EDIT_LINE_INFO:
      return {
        ...state,
        productionlineEditInfo: action.data.status
      };
    case types.PRODUCTIONLINE__DELETE_LINE_INFO:
      return {
        ...state,
        productionlineDeleteInfo: action.data.status
      };
    case types.PRODUCTIONLINE_GET_DETAIL_INFO:
      return {
        ...state,
        productionlineGetDetailData: action.data
      };
    // 工序相关操作
    case types.STATION_GET_LINE_OPTIONS:
      return {
        ...state,
        stationGetOptions: action.data.data1
      };
    case types.STATION_GET_LINE_DATA:
      return {
        ...state,
        stationGetData: action.data
      };
    case types.STATION_ADD_LINE_INFO:
      return {
        ...state,
        stationAddInfo: action.data.status
      };
    case types.STATION_EDIT_LINE_INFO:
      return {
        ...state,
        stationEditInfo: action.data.status
      };
    case types.STATION__DELETE_LINE_INFO:
      return {
        ...state,
        stationDeleteInfo: action.data.status
      };
    case types.STATION_GET_DETAIL_INFO:
      return {
        ...state,
        stationGetDetailData: action.data
      };
    // 车间相关操作
    case types.AREA_GET_LINE_OPTIONS:
      return {
        ...state,
        areaGetOptions: action.data.data1
      };
    case types.AREA_GET_LINE_DATA:
      return {
        ...state,
        areaGetData: action.data
      };
    case types.AREA_ADD_LINE_INFO:
      return {
        ...state,
        areaAddInfo: action.data.status
      };
    case types.AREA_EDIT_LINE_INFO:
      return {
        ...state,
        areaEditInfo: action.data.status
      };
    case types.AREA__DELETE_LINE_INFO:
      return {
        ...state,
        areaDeleteInfo: action.data.status
      };
    case types.AREA_GET_DETAIL_INFO:
      return {
        ...state,
        areaGetDetailData: action.data
      };
    // 熟料生产计划相关
    case types.PRODUCTIONPLAN_GET_OPTIONS:
      return {
        ...state,
        productionPlanGetOptions: action.data.data1
      };
    case types.PRODUCTIONPLAN_GET_TABLE_DATA:
      return {
        ...state,
        productionPlanGetTableData: action.data
      };
    case types.PRODUCTIONPLAN_ADD_DATA_INFO:
      return {
        ...state,
        productionPlanAddDataInfo: action.data.status
      };
    case types.PRODUCTIONPLAN_EDIT_DATA_INFO:
      return {
        ...state,
        productionPlanEditDataInfo: action.data.status
      };
    case types.PRODUCTIONPLAN_DELETE_DATA_INFO:
      return {
        ...state,
        productionPlanDeleteDataInfo: action.data.status
      };
    // 熟料销售计划相关
    case types.PRODUCTIONSALEPLAN_GET_OPTIONS:
      return {
        ...state,
        productionSalePlanGetOptions: action.data.data1
      };
    case types.PRODUCTIONSALEPLAN_GET_TABLE_DATA:
      return {
        ...state,
        productionSalePlanGetTableData: action.data
      };
    case types.PRODUCTIONSALEPLAN_ADD_DATA_INFO:
      return {
        ...state,
        productionSalePlanAddDataInfo: action.data.status
      };
    case types.PRODUCTIONSALEPLAN_EDIT_DATA_INFO:
      return {
        ...state,
        productionSalePlanEditDataInfo: action.data.status
      };
    case types.PRODUCTIONSALEPLAN_DELETE_DATA_INFO:
      return {
        ...state,
        productionSalePlanDeleteDataInfo: action.data.status
      };
    // 原煤价格相关
    case types.COALMILLPRICE_GET_TABLE_DATA:
      return {
        ...state,
        CoalmillPriceGetTableData: action.data
      };
    case types.COALMILLPRICE_ADD_DATA_INFO:
      return {
        ...state,
        CoalmillPriceAddDataInfo: action.data.status
      };
    case types.COALMILLPRICE_EDIT_DATA_INFO:
      return {
        ...state,
        CoalmillPriceEditDataInfo: action.data.status
      };
    case types.COALMILLPRICE_DELETE_DATA_INFO:
      return {
        ...state,
        CoalmillPriceDeleteDataInfo: action.data.status
      };
    // 辅料生产相关
    case types.SUPMATERIALSPRODPLAN_GET_PARTS:
      return {
        ...state,
        SupMaterialsProdPlanGetParts: action.data.data
      };
    case types.SUPMATERIALSPRODPLAN_GET_LINES:
      return {
        ...state,
        SupMaterialsProdPlanGetLines: action.data.data1
      };
    case types.SUPMATERIALSPRODPLAN_GET_TABLE_DATA:
      return {
        ...state,
        SupMaterialsProdPlanGetTableData: action.data
      };
    case types.SUPMATERIALSPRODPLAN_ADD_DATA_INFO:
      return {
        ...state,
        SupMaterialsProdPlanAddDataInfo: action.data.status
      };
    case types.SUPMATERIALSPRODPLAN_EDIT_DATA_INFO:
      return {
        ...state,
        SupMaterialsProdPlanEditDataInfo: action.data.status
      };
    case types.SUPMATERIALSPRODPLAN_DELETE_DATA_INFO:
      return {
        ...state,
        SupMaterialsProdPlanDeleteDataInfo: action.data.status
      };
    // 原煤价格相关
    case types.STORAGE_GET_TABLE_DATA:
      return {
        ...state,
        StorageGetTableData: action.data
      };
    case types.STORAGE_ADD_DATA_INFO:
      return {
        ...state,
        StorageAddDataInfo: action.data.status
      };
    case types.STORAGE_EDIT_DATA_INFO:
      return {
        ...state,
        StorageEditDataInfo: action.data.status
      };
    case types.STORAGE_DELETE_DATA_INFO:
      return {
        ...state,
        StorageDeleteDataInfo: action.data.status
      };
    // 烧失量系数
    case types.CLINKERBURN_GET_TABLE_DATA:
      return {
        ...state,
        ClinkerBurnGetTableData: action.data
      };
    case types.CLINKERBURN_ADD_DATA_INFO:
      return {
        ...state,
        ClinkerBurnAddDataInfo: action.data.status
      };
    case types.CLINKERBURN_EDIT_DATA_INFO:
      return {
        ...state,
        ClinkerBurnEditDataInfo: action.data.status
      };
    case types.CLINKERBURN_DELETE_DATA_INFO:
      return {
        ...state,
        ClinkerBurnDeleteDataInfo: action.data.status
      };
    // 进出库
    case types.STOCKRECORD_GET_TABLE_DATA:
      return {
        ...state,
        StockRecordGetTableData: action.data
      };
    case types.STOCKRECORD_ADD_DATA_INFO:
      return {
        ...state,
        StockRecordAddDataInfo: action.data.status
      };
    // 库存
    case types.STOCK_GET_OPTIONS:
      return {
        ...state,
        StockGetOptions: action.data.data1
      };
    case types.STOCK_GET_TABLE_DATA:
      return {
        ...state,
        StockGetTableData: action.data
      };
    case types.Stock_EDIT_DATA_INFO:
      return {
        ...state,
        StockEditDataInfoSelector: action.data
      };
    // 盘点
    case types.INVENTORY_GET_TABLE_DATA:
      return {
        ...state,
        InventoryGetTableData: action.data
      };
    case types.INVENTORY_ADD_DATA_INFO:
      return {
        ...state,
        InventoryAddDataInfo: action.data.status
      };
    // 事故
    case types.ACCIDENT_GET_OPTIONS:
      return {
        ...state,
        AccidentGetOptions: action.data.data
      };
    case types.ACCIDENT_GET_TABLE_DATA:
      return {
        ...state,
        AccidentGetTableData: action.data
      };
    case types.ACCIDENT_ADD_DATA_INFO:
      return {
        ...state,
        AccidentAddDataInfo: action.data.status
      };
    case types.ACCIDENT_EDIT_DATA_INFO:
      return {
        ...state,
        AccidentEditDataInfo: action.data.status
      };
    case types.ACCIDENT_DELETE_DATA_INFO:
      return {
        ...state,
        AccidentDeleteDataInfo: action.data.status
      };
    // 物料管理
    case types.PART_GET_TABLE_DATA:
      return {
        ...state,
        PartGetTableData: action.data
      };
    case types.PART_ADD_DATA_INFO:
      return {
        ...state,
        PartAddDataInfo: action.data.status
      };
    case types.PART_EDIT_DATA_INFO:
      return {
        ...state,
        PartEditDataInfo: action.data.status
      };
    case types.PART_DELETE_DATA_INFO:
      return {
        ...state,
        PartDeleteDataInfo: action.data.status
      };
    // 电价
    case types.RATES_GET_NAME_OPTIONS:
      return {
        ...state,
        RatesGetNameOptions: action.data.data
      };
    case types.RATES_GET_TYPE_OPTIONS:
      return {
        ...state,
        RatesGetTypeOptions: action.data
      };
    case types.RATES_GET_TABLE_DATA:
      return {
        ...state,
        RatesGetTableData: action.data
      };
    case types.RATES_ADD_DATA_INFO:
      return {
        ...state,
        RatesAddDataInfo: action.data.status
      };
    case types.RATES_EDIT_DATA_INFO:
      return {
        ...state,
        RatesEditDataInfo: action.data.status
      };
    case types.RATES_DELETE_DATA_INFO:
      return {
        ...state,
        RatesDeleteDataInfo: action.data.status
      };
    // 能耗参数
    case types.ENERGY_GET_DRAG_OPTIONS:
      return {
        ...state,
        EnergyGetDragOptions: action.data
      };
    case types.ENERGY_GET_TABLE_DATA:
      return {
        ...state,
        EnergyGetTableData: action.data
      };
    case types.ENERGY_ADD_DATA_INFO:
      return {
        ...state,
        EnergyAddDataInfo: action.data.status
      };
    case types.ENERGY_EDIT_DATA_INFO:
      return {
        ...state,
        EnergyEditDataInfo: action.data.status
      };
    case types.ENERGY_DELETE_DATA_INFO:
      return {
        ...state,
        EnergyDeleteDataInfo: action.data
      };
    // 能耗指标
    case types.ENERGYVALUE_GET_TABLE_DATA:
      return {
        ...state,
        EnergyValueGetTableData: action.data
      };
    case types.ENERGYVALUE_ADD_DATA_INFO:
      return {
        ...state,
        EnergyValueAddDataInfo: action.data.status
      };
    case types.ENERGYVALUE_EDIT_DATA_INFO:
      return {
        ...state,
        EnergyValueEditDataInfo: action.data.status
      };
    case types.ENERGYVALUE_DELETE_DATA_INFO:
      return {
        ...state,
        EnergyValueDeleteDataInfo: action.data.status
      };
    // 水消耗量录入
    case types.WATERCONSUMP_GET_NAME_OPTIONS:
      return {
        ...state,
        WaterComsumpGetNameOptions: action.data.data1
      };
    case types.WATERCONSUMP_GET_TABLE_DATA:
      return {
        ...state,
        WaterComsumpGetTableData: action.data
      };
    case types.WATERCONSUMP_ADD_DATA_INFO:
      return {
        ...state,
        WaterComsumpAddDataInfo: action.data.status
      };
    case types.WATERCONSUMP_EDIT_DATA_INFO:
      return {
        ...state,
        WaterComsumpEditDataInfo: action.data.status
      };
    case types.WATERCONSUMP_DELETE_DATA_INFO:
      return {
        ...state,
        WaterComsumpDeleteDataInfo: action.data.status
      };
    // 检测项目
    case types.QUALITYITEM_GET_TABLE_DATA:
      return {
        ...state,
        QualityItemGetTableData: action.data
      };
    case types.QUALITYITEM_ADD_DATA_INFO:
      return {
        ...state,
        QualityItemAddDataInfo: action.data.status
      };
    case types.QUALITYITEM_EDIT_DATA_INFO:
      return {
        ...state,
        QualityItemEditDataInfo: action.data.status
      };
    case types.QUALITYITEM_DELETE_DATA_INFO:
      return {
        ...state,
        QualityItemDeleteDataInfo: action.data.status
      };
      // 标签管理
      case types.LABEL_GET_TABLE_DATA:
      return {
        ...state,
        LabelGetTableData: action.data
      };
    case types.LABEL_ADD_DATA_INFO:
      return {
        ...state,
        LabelAddDataInfo: action.data.status
      };
    case types.LABEL_EDIT_DATA_INFO:
      return {
        ...state,
        LabelEditDataInfo: action.data.status
      };
    case types.LABEL_DELETE_DATA_INFO:
      return {
        ...state,
        LabelDeleteDataInfo: action.data.status
      };
      // 停机类型
      case types.PROCESSSTOPTYPE_GET_TABLE_DATA:
      return {
        ...state,
        ProcessStopTypeGetTableData: action.data
      };
    case types.PROCESSSTOPTYPE_ADD_DATA_INFO:
      return {
        ...state,
        ProcessStopTypeAddDataInfo: action.data.status
      };
    case types.PROCESSSTOPTYPE_EDIT_DATA_INFO:
      return {
        ...state,
        ProcessStopTypeEditDataInfo: action.data.status
      };
    case types.PROCESSSTOPTYPE_DELETE_DATA_INFO:
      return {
        ...state,
        ProcessStopTypeDeleteDataInfo: action.data.status
      };
    default:
      return state;
  }
};

export const key = "factorycontrol";

reducer.reducer = key;

export default reducer;

module.exports = {
  en_US: {
    label: {
      uom_id: 'Uom_symbol ',
      uom_name: 'Uom_name',
      uomTable: 'uomTable'
    },
    validate: {
      placeholderForuom_id: 'please entry symbol ',
      placeholderForuom_name: 'please entry name'
    }
  },

  zh_CN: {
    label: {
      uom_id: '备件单位符号',
      uom_name: '备件单位名称',
      uomTable: '备件单位列表'
    },
    validate: {
      placeholderForuom_id: '请输入备件单位符号',
      placeholderForuom_name: '请输入备件单位名称'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      departmentCode: 'DepartmentCode',
      departmentName: 'DepartmentName',
      description: 'Description',
      department: 'Department Manage'
    },
    validate: {
      departmentCode: 'place entry departmentCode',
      departmentName: 'place entry departmentName',
      description: 'place entry description'
    }
  },

  zh_CN: {
    label: {
      departmentCode: '部门编码',
      departmentName: '部门名称',
      description: '描述',
      department: '部门管理'
    },
    validate: {
      departmentCode: '请输入部门编码',
      departmentName: '请输入部门名称',
      description: '请输入描述'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      Scheduling: 'Scheduling',
      shift_management: 'ShiftManagement',
      crew_management: 'CrewManagement',
      shiftName: 'shiftName',
      shiftCode: 'shiftCode',
      startTime: 'startTime',
      endTime: 'endTime',
      shift: 'shift',
      isEnable: 'isEnable',
      workshop: 'workshop',
      line: 'Line',
      crewName: 'crewName',
      crewCode: 'crewCode',
      updateTime: 'updateTime',
      options: 'options',
      date_range: 'Daterange',
      Overtime: 'Overtime',
      Saturday: 'Saturday',
      Sunday: 'Sunday',
      holidays: 'Setting up holidays',
      crew: 'crew',
      noShift: 'No shift',
      Holiday: 'Holiday',
      addorupdate: 'Add/Edit',
      process: 'Process'
    },
    validate: {
      placeholderForShiftName: 'please entry name',
      placeholderForShiftCode: 'please entry code',
      placeholderForWorkShop: 'please entry workshop',
      placeholderForLine: 'please entry line',
      placeholderFordate_range: 'please entry Daterange',
      placeholderForshift: 'please entry shift',
      placeholderForcrew: 'please entry crew'
    },
  },

  zh_CN: {
    label: {
      Scheduling: '排班',
      shift_management: '班次管理',
      crew_management: '班组管理',
      shiftName: '班次名称',
      shiftCode: '班次编码',
      shift: '班次',
      startTime: '开始时间',
      endTime: '结束时间',
      isEnable: '是否启用',
      workshop: '车间',
      line: '产线',
      crewName: '班组名称',
      crewCode: '班组编号',
      updateTime: '上次更新时间',
      options: '操作',
      date_range: '日期范围',
      Overtime: '加班',
      Saturday: '周六',
      Sunday: '周日',
      holidays: '设置假期',
      crew: '班组',
      noShift: '暂无排班',
      Holiday: '假期',
      addorupdate: '添加/修改',
      process: '工序'
    },
    validate: {
      placeholderForShiftName: '输入班次名称',
      placeholderForShiftCode: '输入班次编号',
      placeholderForWorkShop: '请选择车间类型',
      placeholderForLine: '请选择产线',
      placeholderFordate_range: '请选择日期范围',
      placeholderForshift: '请选择班次',
      placeholderForcrew: '请选择班组'
    },
  }
};

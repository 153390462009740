module.exports = {
  en_US: {
    validate: {
      placeholderForName: 'please entry menu name',
      placeholderForAddress: 'please entry address name',
      placeholderForcodeName: 'please entry code name',
      placeholderForshowName: 'please entry show name',
      placeholderForSortCode: 'please entry sort code',
      placeholderForenglishName: 'please entry english name',
      placeholderForIcon: 'please choose icon!',
      placeholderForenglishDesc: 'please entry description',
    },
    label: {
      titleForName: 'menu name',
      titleForAddress: 'visit address',
      titleForOperate: 'operate',
      btnList: 'button add/list',
      menuManage: 'menu manage',
      codeName: 'code name',
      showName: 'chinese name',
      showEnName: 'english name',
      menuTable: 'MenuTable',
      enableMark: 'enable',
      icon: 'Icon',
      sortCode: 'sort',
      parentNode: 'Parent Node',
      englishName: 'english name',
      desc: 'description'
    },
    action: {
      add: 'add',
      modify: 'modify',
      delete: 'delete',
      save: 'save',
      cancel: 'cancel',
      close: 'close',
      editBtn: 'edit button',
      delSuccess: 'delete success',
      delError: 'delete error',
      modifySuccess: 'modify suucess',
      modifyError: 'modify error',
      addSuccess: 'add success',
      addError: 'add error',
      editMenu: 'edit menu',
      addMenu: 'add menu',
      newAdd: 'new add',
      addSubMenu: 'add submenu',
      editSubMenu: 'add submenu'
    }
  },
  zh_CN: {
    validate: {
      placeholderForName: '请输入菜单名称',
      placeholderForAddress: '请输入访问地址',
      placeholderForcodeName: '请输入名称编码',
      placeholderForshowName: '请输入中文名称',
      placeholderForSortCode: '请输入排序值',
      placeholderForenglishName: '请输入英文名称',
      placeholderForenglishDesc: '请输入描述',
      placeholderForIcon: '请选择图标!',
    },
    label: {
      titleForName: '菜单名称',
      titleForAddress: '访问地址',
      titleForOperate: '操作',
      btnList: '按钮 新增/列表',
      menuManage: '菜单管理',
      menuTable: '菜单列表',
      codeName: '名称编码',
      showName: '中文名称',
      showEnName: '英文名称',
      enableMark: '启用',
      icon: '图标',
      sortCode: '排序',
      parentNode: '父节点',
      englishName: '英文名',
      desc: '描述'
    },
    action: {
      add: '添加',
      modify: '修改',
      delete: '删除',
      save: '保存',
      close: '关闭',
      cancel: '取消',
      editBtn: '编辑按钮',
      delSuccess: '删除成功',
      delError: '删除失败',
      modifySuccess: '修改成功',
      modifyError: '修改失败',
      addSuccess: '增加成功',
      addError: '增加失败',
      newAdd: '新增',
      addMenu: '新增菜单',
      editMenu: '编辑菜单',
      addSubMenu: '新增子菜单',
      editSubMenu: '编辑子菜单'
    }
  }
};

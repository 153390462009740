module.exports = {
  en_US: {
    label: {
      code: 'BOM Code',
      name: 'BOM Name',
      bomInfo: 'BOMInfo',
      bomEdit: 'BOMEdit',
      version: 'version',
      status: 'status',
      unit: 'unit',
      operate: 'operate',
      bomitem_Code: 'bomitemCode',
      addSubItemBom: 'AddSubBomItem',
      bomitem_Name: 'bomitemName',
      material_eigen: 'material(eigenvalue)',
      type: 'type',
      number: 'number',
      addItemBom: 'AddBomItem',
      expand: 'expand',
      subitem_Code: 'subItemCode',
      subitem_Name: 'subItemName',
      substitution_rate: 'substitutionRate',
      bomitem: 'BomItem Ddit',
      materialView: 'Material View',
      bomsubitem: 'BomsubItem Edit',
      bomitemTitle: 'BomItem',
      bomSubitemTitle: 'BomSubItem',
      bomTable: 'BOMTable',
      inputunit: 'Unit',
      bomExtend: 'bomExtend',
      bomItemExtend: 'bomItemExtend',
      bomSubItemExtend: 'bomSubItemExtend',
    },
    action: {
      search: 'search',
      delete: 'delete',
      add: 'add',
      modify: 'modify',
      copy: 'copy',
      extend: 'extend'
    },
    validate: {
      placeholderForName: 'please entry bom name',
      placeholderForCode: 'please entry bom code',
      placeholderForItemName: 'please entry bomItem name',
      placeholderForItemCode: 'please entry bomItem code',
      placeholderForVersion: 'please entry bom version',
      placeholderForVersion1: 'please entry bom version',
      placeholderForUnit: 'please entry bom Unit',
      placeholderForItemSubName: 'please entry subItem name',
      placeholderForMaterial: 'please entry material',
      placeholderForItemSubCode: 'please entry subItem code',
      placeholderForType: 'please entry bom type',
      copyForVersion: 'copy bom version',
      validForDelete: 'Please select an item that is legally deleted',
    },
    type: {
      pigeonhole: 'pigeonhole',
      new: 'new',
      tack_effect: 'tack_effect',
      input: 'input',
      output: 'output',
      semi_finished: 'semi_finished',
      choosable: 'choosable'
    },
  },
  zh_CN: {
    label: {
      code: 'BOM编码',
      name: 'BOM名称',
      bomInfo: 'BOM详情',
      bomEdit: 'BOM编辑',
      version: 'BOM版本',  
      status: '状态',
      unit: '单位',
      inputunit: '计量单位',
      operate: '操作',
      bomitem_Code: 'bomitem编码',
      bomitem_Name: 'bomitem名称',
      material_eigen: '物料(特征值)',
      type: '类型',
      number: '数量',
      expand: '拓展',
      addItemBom: '新增BomItem',
      addSubItemBom: '新增SubBomItem',
      subitem_Code: 'subItem编码',
      subitem_Name: 'subItem名称',
      substitution_rate: '替换比例',
      bomitem: 'bomitem编辑',
      materialView: '物料查看',
      bomsubitem: 'bomsubitem编辑',
      bomitemTitle: 'BomItem',
      bomSubitemTitle: 'BomSubItem',
      bomTable: 'BOM列表',
      bomExtend: 'Bom扩展',
      bomItemExtend: 'BomItem扩展',
      bomSubItemExtend: 'BomSubItem扩展',
    },
    action: {
      search: '查找',
      delete: '删除',
      add: '增加',
      modify: '修改',
      copy: '复制',
      extend: '扩展'
    },
    validate: {
      placeholderForName: '请输入bom名称',
      placeholderForCode: '请输入bom编码',
      placeholderForItemName: '请输入BomItem名称',
      placeholderForItemCode: '请输入BomItem编码',
      placeholderForItemSubName: '请输入SubItem名称',
      placeholderForItemSubCode: '请输入SubItem编码',
      placeholderForVersion: '复制Bom',
      placeholderForVersion1: '请输入版本号',
      copyForVersion: '请输入新的版本号',
      placeholderForType: '请选择状态类型',
      placeholderForMaterial: '请选择物料(特征值)',
      placeholderForUnit: '请选择计量单位',
      validForDelete: '请选择合法删除的项',
    },
    type: {
      pigeonhole: '归档',
      new: '新建',
      tack_effect: '生效',
      input: '投入',
      output: '产出',
      semi_finished: '半成品',
      choosable: '可选'
    },
  }
};

export const GET_MEIFEN1 = 'quality1/GET_MEIFEN1';

export const GET_SHAO = 'quality1/GET_SHAO';

export const GET_SHUI = 'quality1/GET_SHUI';

export const GET_YUAN = 'quality1/GET_YUAN';

export const GET_SHAOSTANDREN = 'quality1/GET_SHAOSTANDREN';

export const GET_YUANSTANDREN = 'quality1/GET_YUANSTANDREN';

export const GET_PAIHANG = 'quality1/GET_PAIHANG';

export const GET_KSM = 'quality1/GET_KSM';

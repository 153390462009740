module.exports = {
  en_US: {
    label: {
      lubricationContentName: 'lubricationContentName',
      lubricationContentCode: 'lubricationContentCode',
      sparePartTable: 'sparePartTable',
      equipmentPosition: 'MaintainPosition',
      equipmentWork: 'MaintainWork',
      isHalt: 'isHalt',
      timeNeeded: 'timeNeeded(min)',
      sparePart: 'sparePart',
      selectsparePart: 'select SparePart',
      enable: 'enable',
      remark: 'remark',
      upload: 'upload image',
      titleAdd: 'Create > select part',
      titleEdit: 'Edit > select part',
      createTime: 'createTime'
    },
    validate: {
      placeholderForlubricationContentName: 'please entry lubricationContentName',
      placeholderForlubricationContentCode: 'please entry lubricationContentCode',
      placeholderForequipmentWork: 'please entry MaintainWork',
      placeholderFortimeNeeded: 'please entry time needed',
      placeholderForremark: 'please entry remark'
    }
  },

  zh_CN: {
    label: {
      lubricationContentName: '润滑内容名称',
      lubricationContentCode: '润滑内容编码',
      sparePartSelect: '备件选择',
      sparePartTable: '备件列表',
      equipmentPosition: '保养部位',
      equipmentWork: '保养工作',
      isHalt: '是否停机',
      timeNeeded: '所需工时(分钟)',
      sparePart: '备件',
      selectsparePart: '选择备件',
      enable: '是否启用',
      remark: '备注',
      upload: '上传图片',
      titleAdd: '新增 > 选择配件',
      titleEdit: '修改 > 选择配件',
      createTime: '创建时间',
    },
    validate: {
      placeholderForlubricationContentName: '请输入润滑内容名称',
      placeholderForlubricationContentCode: '请输入润滑内容编码',
      placeholderForequipmentWork: '请输入保养工作',
      placeholderFortimeNeeded: '请输入所需工时',
      placeholderForremark: '请输入备注'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      timerCode: 'timerCode',
      timerName: 'timerName',
      timerGroup: 'timerGroup',
      timerType: 'timerType',
      date: 'dateTime',
      execute: 'Execute',
      active: 'enable',
      description: 'description'
    },
    validate: {
      placeholderFortimerCode: 'please entry timerCode',
      placeholderFortimerName: 'please entry timerName',
      placeholderForuom_description: 'please entry description',
      placeholderFortimerGroup: 'please select timerGroup',
      placeholderFortimerType: 'please select timerType',
      placeholderFortimer: 'please select date',
      placeholderFordayInterval: 'please entry dayInterval',
      placeholderForweekDays: 'please entry weekDays',
      placeholderFor: 'please entry',
    },
    action: {
      create: 'create timer',
      update: 'update timer'
    }
  },

  zh_CN: {
    label: {
      timerCode: '周期计划编码',
      timerName: '周期计划名称',
      timerGroup: '周期计划分组',
      timerType: '周期计划类型',
      date: '时间日期',
      execute: '执行',
      active: '是否启用',
      description: '描述'
    },
    validate: {
      placeholderFortimerCode: '请输入周期计划编码',
      placeholderFortimerName: '请输入周期计划名称',
      placeholderFortimerGroup: '请选择周期计划分组',
      placeholderFortimerType: '请选择周期计划类型',
      placeholderFortimer: '请选择时间',
      placeholderFordayInterval: '请输入间隔天数',
      placeholderFor: '请输入',
    },
    action: {
      create: '新增周期计划',
      update: '修改周期计划'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      typeForMaterial_id: 'Code',
      typeForMaterial_description: 'Description'
    },
    validate: {
      placeholderForCode: 'place entry code',
      placeholderForDesc: 'place entry descriptio'
    }
  },

  zh_CN: {
    label: {
      typeForMaterial_id: '物料分组编码',
      typeForMaterial_description: '物料分组描述'
    },
    validate: {
      placeholderForCode: '请输入物料分组编码',
      placeholderForDesc: '请输入物料分组描述'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      maintainPlanNum: 'maintainPlanNum',
      maintainPlanName: 'maintainPlanName',
      equipmentName: 'equipmentName',
      factory: 'factory',
      maintainPlanConditionName: 'status',
      transactorName: 'transactorName',
      startUsing: 'startUsing',
      maintainPlanStartTime: 'StartTime',
      maintainPlanEndTime: 'EndTime',
      equipmentname: 'please select equipment ',
      equipment: 'equipment',
      condition: 'planState',
      formaintainPlanName: 'please entry maintainPlanName',
      maintainContent: 'maintainContent',
      equipmentLocation: 'DeviceLocation',
      transactor: 'transactor',
      shutDown: 'shutDown',
      auditor: 'auditor',
      titleAdd: 'Create > allocation Timer',
      titleEdit: 'Edit > allocation Timer',
      deadline: 'deadline(day)',
      fordeadline: 'please entry deadline(day)',
      autoLub: 'Automatic application Lub',
      autoSpare: 'Automatic application Spare'
    },
    validate: {
      placeholderForequipmentPosition: 'please select maintainContent',
      placeholderForEquipment: 'please select equipment',
      placeholderFormaintainPlanCode: 'please entry maintainPlanCode',
      placeholderFortransactor: 'please select transactor',
      placeholderForauditor: 'please select auditor',
    }
  },

  zh_CN: {
    label: {
      maintainPlanNum: '保养计划编码',
      maintainPlanName: '保养计划名称',
      equipmentName: '设备名称',
      transactorName: '执行人',
      factory: '设备位置',
      maintainPlanConditionName: '计划状态',
      startUsing: '是否启用',
      maintainPlanStartTime: '计划开始时间',
      maintainPlanEndTime: '计划结束时间',
      equipmentname: '请选择设备',
      equipment: '设备',
      condition: '计划状态',
      formaintainPlanName: '请输入保养计划名称',
      maintainContent: '保养内容',
      equipmentLocation: '设备位置',
      transactor: '执行人',
      auditor: '审核人',
      shutDown: '是否停机',
      titleAdd: '新增 > 配置Timer',
      titleEdit: '修改 > 配置Timer',
      deadline: '限制时间(天)',
      fordeadline: '请输入限制时间(天)',
      autoLub: '是否自动申请油品',
      autoSpare: '是否自动申请备件'
      
    },
    validate: {
      placeholderFormaintainContent: '请选择保养内容',
      placeholderForEquipment: '请选择设备',
      placeholderFormaintainPlanCode: '请输入保养计划编码',
      placeholderFortransactor: '请选择执行人',
      placeholderForauditor: '请选择审核人',
    }
  }
};

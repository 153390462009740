module.exports = {
  en_US: {
    label: {
      inStorageTable: 'inStorageTable ',
      receiptNumber: 'code',
      title: 'title',
      source: 'source',
      createTime: 'createTime',
      isPass: 'status',
      remarks: 'remarks',
      applyUser: 'applyUser',
      details: 'details',
      outStorageTable: 'outStorageTable',
      titleAdd: 'add > Edit sparePart',
      titleEdit: 'Edit > Edit sparePart',
      time: 'time',
    },
    validate: {
      placeholderForreceiptNumber: 'please entry code ',
      placeholderFortitle: 'please entry title',
      placeholderForaccessTy: 'please entry accessType',
      placeholderForapplyUser: 'please select applyUser',
      placeholderForremarks: 'please entry remarks',
      placeholderForsource: 'please entry source',
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      inStorageTable: '入库记录',
      receiptNumber: '单号',
      title: '标题',
      source: '来源',
      createTime: '创建时间',
      isPass: '状态',
      remarks: '备注',
      applyUser: '申请人',
      details: '详情',
      outStorageTable: '出库记录',
      titleAdd: '新增 > 编辑配件',
      titleEdit: '详情 > 编辑配件',
      time: '创建时间'
    },
    validate: {
      placeholderForreceiptNumber: '请输入单号',
      placeholderFortitle: '请输入标题',
      placeholderForaccessTy: '请输入申请类型',
      placeholderForapplyUser: '请选择申请人',
      placeholderForremarks: '请输入备注',
      placeholderForsource: '请输入来源',
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

/*
 * @Author: Otway
 * @Date: 2019-08-19 13:39:06
 * @LastEditors: Otway
 * @LastEditTime: 2019-08-19 14:40:15
 * @copyright: h-visions
 */
export const GET_OPERATION_LIST = 'energy/GET_OPERATION_LIST';

export const GET_EXTENDS_COLUMNS = 'energy/GET_EXTENDS_COLUMNS';

export const GET_OPEATION_DETAIL_LIST = 'energy/GET_OPEATION_DETAIL_LIST';

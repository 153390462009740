import { createSelector } from 'reselect';
import { isEmpty } from 'lodash';
import { getToken, getLocale, getAuthData } from '~/util/session';
import { key } from '.';

export const selectLoginSuccess = () => !!getToken();

export const userSelector = () => getAuthData();

export const localeSelector = () => getLocale();

export const isChineseLocale = createSelector(localeSelector, locale => locale === 'zh_CN');

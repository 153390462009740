module.exports = {
  en_US: {
    label: {
      typeCode: 'typeCode ',
      typeName: 'typeName',
      typeTable: 'lubTypeTable'
    },
    validate: {
      placeholderFortypeCode: 'please entry typeCode ',
      placeholderFortypeName: 'please entry typeName'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      typeCode: '油品类型编号',
      typeName: '油品类型名称',
      typeTable: '油品类型列表'
    },
    validate: {
      placeholderFortypeCode: '请输入油品类型编码',
      placeholderFortypeName: '请输入油品类型名称'
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

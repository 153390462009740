export const GET_RECORD_CODE = 'RuntimeRecord/GET_RECORD_CODE';

export const GET_TABLE_BY_CODE = 'RuntimeRecord/GET_TABLE_BY_CODE';

export const GET_TABLE_FIRING_HEAD = 'RuntimeRecord/GET_TABLE_FIRING_HEAD';
export const GET_TABLE_COAL_PREPARATION = 'RuntimeRecord/GET_TABLE_COAL_PREPARATION';
export const GET_TABLE_FIRING_MIDDLE = 'RuntimeRecord/GET_TABLE_FIRING_MIDDLE';
export const GET_TABLE_RAW_MILL = 'RuntimeRecord/GET_TABLE_RAW_MILL';
export const GET_TABLE_CEMENT_MILL = 'RuntimeRecord/GET_TABLE_CEMENT_MILL';
export const GET_TABLE_WASTE = 'RuntimeRecord/GET_TABLE_WASTE';
export const GET_TABLE_COGENERATION = 'RuntimeRecord/GET_TABLE_COGENERATION';

export const GET_SHIFT_FIRING_HEAD = 'RuntimeRecord/GET_SHIFT_FIRING_HEAD';
export const GET_SHIFT_COAL_PREPARATION = 'RuntimeRecord/GET_SHIFT_COAL_PREPARATION';
export const GET_SHIFT_FIRING_MIDDLE = 'RuntimeRecord/GET_SHIFT_FIRING_MIDDLE';
export const GET_SHIFT_RAW_MILL = 'RuntimeRecord/GET_SHIFT_RAW_MILL';
export const GET_SHIFT_CEMENT_MILL = 'RuntimeRecord/GET_SHIFT_CEMENT_MILL';
export const GET_SHIFT_WASTE = 'RuntimeRecord/GET_SHIFT_WASTE';
export const GET_SHIFT_COGENERATION = 'RuntimeRecord/GET_SHIFT_COGENERATION';

export const GET_STATISTIC_COAL_PREPARATION = 'RuntimeRecord/GET_STATISTIC_COAL_PREPARATION';
export const GET_STATISTIC_FIRING_MIDDLE = 'RuntimeRecord/GET_STATISTIC_FIRING_MIDDLE';
export const GET_STATISTIC_RAW_MILL = 'RuntimeRecord/GET_STATISTIC_RAW_MILL';
export const GET_STATISTIC_CEMENT_MILL = 'RuntimeRecord/GET_STATISTIC_CEMENT_MILL';

export const GET_ALL_NAME = 'RuntimeRecord/GET_ALL_NAME';

module.exports = {
  en_US: {
    input: {
      type: 'Please input the type',
      type_code: 'Please entry the equipment type code',
      type_name: 'Please entry the equipment type name',
      equipmenttype: 'TypeName',
      equipmentDesc: 'TypeCode',
      equipmenttypeName: 'equipmentType',
      parameterCode: 'parameterCode',
      parameterName: 'parameterName',
      dataType: 'dataType',
      unit: 'unit',
      readOnly: 'readOnly',
      description: 'description',
      remark: 'remark',
      TypeParameter: 'equipmentParameter'
    },
    validate: {
      placeholderForparameterCode: 'please entry parameterCode',
      placeholderForparameterName: 'please entry parameterName',
      placeholderFordataType: 'please entry dataType',
      placeholderForunit: 'please entry unit',
      placeholderForreadOnly: 'please entry readOnly',
      placeholderFordescription: 'please entry description',
      placeholderForremark: 'please entry remark'
    }
  },

  zh_CN: {
    input: {
      type: '请输入设备类型',
      type_code: '请输入类型编码',
      type_name: '请输入类型名称',
      equipmenttype: '类型名称',
      equipmentDesc: '类型编码',
      equipmenttypeName: '设备类型',
      parameterCode: '参数编码',
      parameterName: '参数名称',
      dataType: '数据类型',
      unit: '参数单位',
      readOnly: '参数类型',
      description: '描述',
      remark: '备注',
      TypeParameter: '设备类型参数'
    },
    validate: {
      placeholderForparameterCode: '请输入参数编码',
      placeholderForparameterName: '请输入参数名称',
      placeholderFordataType: '请选择数据类型',
      placeholderForunit: '请输入参数单位',
      placeholderForreadOnly: '请选择参数类型',
      placeholderFordescription: '请输入描述',
      placeholderForremark: '请输入备注'
    }
  }
};

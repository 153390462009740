module.exports = {
  en_US: {
    label: {
      lubricationItemCode: 'lubricationItemCode',
      lubricationItemName: 'lubricationItemName',
      lubricationWay: 'lubricationWay',
      lubricationPoints: 'lubricationPoints',
      sparePartSelect: 'sparePartSelect',
      sparePartTable: 'sparePartTable',
      lubricationPosition: 'lubricationPosition',
      tools: 'tools',
      recruitment: 'recruitment',
      supplementaryCycle: 'supplementaryCycle',
      exchangeOilCycle: 'exchangeOilCycle',
      isHalt: 'isHalt',
      timeNeeded: 'timeNeeded(min)',
      sparePart: 'sparePart',
      selectsparePart: 'select SparePart',
      enable: 'enable',
      remark: 'remark',
      upload: 'upload image',
      titleAdd: 'Create > select oils',
      titleEdit: 'Edit > select oils'
    },
    validate: {
      placeholderForlubricationPosition: 'please entry lubricationPosition',
      placeholderForlubricationPoints: 'please entry lubricationPoints',
      placeholderForlubricationWay: 'please entry lubricationWay',
      placeholderFortimeNeeded: 'please entry timeNeeded',
      placeholderForremark: 'please entry remark',
      placeholderFortools: 'please entry tools',
      placeholderForrecruitment: 'please entry recruitment',
      placeholderForsupplementaryCycle: 'please entry supplementaryCycle',
      placeholderForexchangeOilCycle: 'please entry exchangeOilCycle',
      placeholderForlubricationItemCode: 'please entry lubricationItemCode',
      placeholderForlubricationItemName: 'please entry lubricationItemName'
    }
  },

  zh_CN: {
    label: {
      sparePartSelect: '备件选择',
      sparePartTable: '备件列表',
      equipmentPosition: '保养部位', 
      lubricationWay: '润滑方式',
      lubricationPoints: '润滑点数',
      lubricationItemCode: '润滑项目编码',
      lubricationItemName: '润滑项目名称',
      lubricationPosition: '润滑部位',
      tools: '使用工具',
      recruitment: '补充量/标准',
      supplementaryCycle: '补充周期',
      exchangeOilCycle: '换油周期',
      isHalt: '是否停机',
      timeNeeded: '所需工时(分钟)',
      sparePart: '备件',
      selectsparePart: '选择备件',
      enable: '是否启用',
      remark: '备注',
      upload: '上传图片',
      titleAdd: '新增 > 选择油品',
      titleEdit: '修改 > 选择油品'
    },
    validate: {
      placeholderForlubricationPosition: '请输入润滑部位',
      placeholderForlubricationPoints: '请输入润滑点数',
      placeholderForlubricationWay: '请输入润滑方式',
      placeholderForequipmentWork: '请输入保养工作',
      placeholderFortimeNeeded: '请输入所需工时',
      placeholderForremark: '请输入备注',
      placeholderFortools: '请输入实用工具',
      placeholderForrecruitment: '请输入补充量/标准',
      placeholderForsupplementaryCycle: '请输入补充周期',
      placeholderForexchangeOilCycle: '请输入换油周期',
      placeholderForlubricationItemCode: '请输入润滑项目编码',
      placeholderForlubricationItemName: '请输入润滑项目名称'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      operationName: 'operationName',
      operationCode: 'operationCode',
      operationCode1: 'operationCode',
      operationName1: 'operationName',
      addBind: 'Add binding',
      bindfile: 'bindfile',
      productionPra: 'Production process parameters',
      qualityPar: 'Quality process parameters'
    },
    validate: {
      placeholderForName: 'please entry operationName',
      placeholderForCode: 'please entry operationCode',
    }
  },

  zh_CN: {
    label: {
      operationName: '工艺操作名称',
      operationCode: '工艺操作编码',
      operationCode1: '操作编码',
      operationName1: '操作名称',
      addBind: '添加绑定',
      bindfile: '绑定文件',
      productionPra: '生产工艺参数',
      qualityPar: '质量工艺参数'
    },
    validate: {
      placeholderForName: '请输入工艺操作名称',
      placeholderForCode: '请输入工艺操作编码',
    }
  }
};

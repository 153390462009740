import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import asyncRoute from './asyncRoute';

const HomePage = asyncRoute(() => import('./Home'));
const LoginPage = asyncRoute(() => import('../Login'));

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <AuthenticatedRoute
          path="/"
          loginPath="/login"
          component={HomePage}
        />
      </Switch>
    );
  }
}

export default withRouter(App);

module.exports = {
  en_US: {
    label: {
      lubricationPlanNum: 'lubricationPlanNum',
      lubricationPlanName: 'lubricationPlanName',
      equipmentName: 'equipmentName',
      factory: 'factory',
      maintainPlanConditionName: 'PlanCondition',
      transactorName: 'transactorName',
      startUsing: 'startUsing',
      maintainPlanStartTime: 'StartTime',
      maintainPlanEndTime: 'EndTime',
      equipmentname: 'please select equipment ',
      equipment: 'equipment',
      condition: 'statePlan',
      formaintainPlanName: 'please entry maintainPlanName',
      lubricationPlanConditionName: 'status',
      lubricationContent: 'lubricationContent',
      equipmentLocation: 'DeviceLocation',
      transactor: 'transactor',
      shutDown: 'shutDown',
      titleAdd: 'Create > allocation Timer',
      titleEdit: 'Edit > allocation Timer'
    },
    validate: {
      placeholderForequipmentPosition: 'please select maintainContent',
      placeholderForlubricationPlanNum: 'please entry lubricationPlanNum',
      placeholderForlubricationPlanName: 'please entry lubricationPlanName',
      placeholderForEquipment: 'please select equipment',
      placeholderFormaintainPlanCode: 'please entry maintainPlanCode',
      placeholderFortransactor: 'please select transactor',
      placeholderForlubricationContent: 'please select lubricationContent',
      placeholderForcondition: 'please select condition',
    }
  },

  zh_CN: {
    label: {
      lubricationPlanNum: '润滑计划编码',
      lubricationPlanName: '润滑计划名称',
      equipmentName: '设备名称',
      transactorName: '执行人',
      factory: '所在位置',
      maintainPlanConditionName: '审批状态',
      startUsing: '是否停机',
      maintainPlanStartTime: '计划开始时间',
      maintainPlanEndTime: '计划结束时间',
      equipmentname: '请选择设备',
      equipment: '设备',
      condition: '计划状态',
      formaintainPlanName: '请输入润滑计划名称',
      lubricationContent: '润滑内容',
      lubricationPlanConditionName: '计划状态',
      equipmentLocation: '设备位置',
      transactor: '执行人',
      shutDown: '是否启用',
      titleAdd: '新增 > 配置Timer',
      titleEdit: '修改 > 配置Timer'
      
    },
    validate: {
      placeholderForlubricationContent: '请选择润滑内容',
      placeholderForlubricationPlanNum: '请输入润滑计划编码',
      placeholderForlubricationPlanName: '请输入润滑计划名称',
      placeholderForEquipment: '请选择设备',
      placeholderFortransactor: '请选择执行人',
      placeholderForcondition: '请选择计划状态',
    }
  }
};

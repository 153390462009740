module.exports = {
  en_US: {
    label: {
      cutterCode: 'cutterCode',
      cutterName: 'cutterName',
      batchNumber: 'batchNumber',
      company: 'company',
      supplier: 'supplier',
      company1: 'company',
      cutterType: 'cutterType',
      unitPrice: 'unitPrice',
      cargoLocation: 'cargoLocation',
      cargoMax: 'cargoMax',
      cargoMin: 'cargoMin',
      cutterTable: 'cutterTable',
      cutterCount: 'cutterCount',
      remarks: 'remarks',
      alarmRemainingNumber: 'alarmRemainingNumber',
      expectedNumber: 'expectedNumber'
    },
    validate: {
      placeholderForcutterCode: 'please entry cutterCode',
      placeholderForcutterName: 'please entry cutterName',
      placeholderForbatchNumber: 'please entry batchNumber',
      placeholderForcompany: 'please entry company',
      placeholderForsupplier: 'please entry supplier',
      placeholderForcargoLocation: 'please entry cargoLocation',
      placeholderForcargoMax: 'please entry cargoMax',
      placeholderForcutterType: 'please entry cutterType',
      placeholderForcargoMin: 'please entry cargoMin',
      placeholderForunitPrice: 'please entry unitPrice',
      placeholderForalarmRemainingNumber: 'please entry alarmRemainingNumber',
      placeholderForexpectedNumber: 'please entry expectedNumber',
      placeholderForremarks: 'please entry remarks'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      cutterCode: '刀具编码',
      cutterName: '刀具名称',
      batchNumber: '刀具批次号',
      company: '刀具单位',
      company1: '刀具单位',
      supplier: '刀具供应商',
      cutterType: '刀具类型',
      cargoLocation: '刀具库房',
      unitPrice: '刀具单价',
      cargoMax: '刀具最大库存',
      cargoMin: '刀具最小库存',
      cutterTable: '刀具列表',
      cutterCount: '刀具数量',
      remarks: '备注',
      alarmRemainingNumber: '报警临界剩余数量',
      expectedNumber: '预计可用次数'
    },
    validate: {
      placeholderForcutterCode: '请输入刀具编码',
      placeholderForcutterName: '请输入刀具名称',
      placeholderForbatchNumber: '请输入刀具批次号',
      placeholderForcompany: '请选择刀具单位',
      placeholderForsupplier: '请输入刀具供应商',
      placeholderForcutterType: '请选择刀具类型',
      placeholderForcargoLocation: '请选择刀具库房',
      placeholderForcargoMax: '请输入刀具最小库存',
      placeholderForcargoMin: '请输入刀具最大库存',
      placeholderForunitPrice: '请输入刀具单价',
      placeholderForalarmRemainingNumber: '请输入报警临界剩余数量',
      placeholderForexpectedNumber: '请输入预计可用次数',
      placeholderForremarks: '请输入备注',
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      receiptNumber: 'code',
      title: 'title',
      accessTy: 'accessType',
      createTime: 'createTime',
      isPass: 'status',
      remarks: 'remarks',
      applyUser: 'applyUser',
      details: 'details',
      outStorageTable: 'maintenanceTable',
      equipmentName: 'equipmentName',
      equipmentCode: 'equipmentCode',
      priority: 'priority',
      failureLevel: 'failureLevel',
      equipment: 'equipment',
      faultClass: 'faultClass',
      fault: 'fault',
      failureLevel1: 'urgency',
      failureLevel2: 'ordinary',
      equipmentLocation: 'equipmentLocation'
    },
    validate: {
      placeholderForreceiptNumber: 'please entry code ',
      placeholderForequipmentLocation: 'please entry equipmentLocation',
      placeholderForpriority: 'please select priority',
      placeholderForapplyUser: 'please select applyUser',
      placeholderForremarks: 'please entry remarks',
      placeholderForfaultClass: 'please select faultClass',
      placeholderForequipment: 'please select equipment',
      placeholderForfault: 'please select fault',
      placeholderForfailureLevel: 'please select failureLevel',
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      receiptNumber: '单号',
      title: '标题',
      accessTy: '申请类型',
      createTime: '创建时间',
      isPass: '状态',
      remarks: '备注',
      applyUser: '申请人',
      details: '详情',
      outStorageTable: '维修工单列表',
      equipmentName: '设备名称',
      priority: '紧急度',
      equipmentCode: '设备编码',
      failureLevel: '故障等级',
      equipment: '设备',
      faultClass: '故障类型',
      fault: '故障表现',
      failureLevel1: '紧急',
      failureLevel2: '一般',
      equipmentLocation: '设备位置'
    },
    validate: {
      placeholderForreceiptNumber: '请输入单号',
      placeholderForequipmentLocation: '请输入设备位置',
      placeholderForpriority: '请选择紧急度',
      placeholderForapplyUser: '请选择申请人',
      placeholderForremarks: '请输入备注',
      placeholderForfaultClass: '请选择故障类型',
      placeholderForequipment: '请选择设备',
      placeholderForfault: '请选择故障表现',
      placeholderForfailureLevel: '请选择故障等级',
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      cutterName: 'cutterName',
      spareInventory: 'spareInventoryTable',
      cutter: 'cutter',
      storehouse: 'storehouse',
      cutterType: 'cutterType',
      cutterNumber: 'cutterNumber',
      cutterCode: 'cutterCode',
      batchNumber: 'batchNumber',
      maintainAfterNum: 'maintainAfterNum',
      maintainNum: 'maintainNum',
      state: 'status',
      useNum: 'useNum',
      desc: 'description'
    },
    validate: {
      placeholderForcutterCode: 'please entry cutterCode',
      placeholderFortypeCode: 'please entry spareNumber ',
      placeholderForstorehouse: 'please entry storehouse',
      placeholderForcutterType: 'please entry cutterType',
      placeholderForcutterNumber: 'please entry cutterNumber'
    }
  },

  zh_CN: {
    label: {
      cutterCode: '刀具编码',
      cutterName: '刀具名称',
      spareInventory: '刀具库存列表',
      cutter: '刀具',
      storehouse: '刀具库房',
      cutterType: '刀具类型',
      cutterNumber: '刀具数量',
      batchNumber: '批次号',
      maintainAfterNum: '消磨后可用次数',
      maintainNum: '消磨次数',
      state: '状态',
      useNum: '使用次数',
      desc: '描述'
    },
    validate: {
      placeholderForcutterCode: '请输入刀具编码',
      placeholderForcutter: '请选择刀具',
      placeholderForstorehouse: '请选择刀具库房',
      placeholderForcutterType: '请选择刀具类型',
      placeholderForcutterNumber: '请输入刀具数量'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      cutterCode: 'cutterCode',
      cutter: 'cutter',
      cutterName: 'cutterName',
      cutterBoundList: 'cutterBoundList',
      cutterBound: 'cutterBound',
      cl: 'cutterLocation',
      spindle: 'spindle',
      state: 'state',
      toolBatchNum: 'cutterBatch',
      totalEquipment: 'totalEquipment',
      equipmentCode: 'equipmentCode',
      equipmentName: 'equipmentName',
      equipmentTypeName: 'equipmentTypeName',
      equipmentModel: 'equipmentModel',
      equipmentSerialNum: 'equipmentSerialNum',
      manufacturer: 'manufacturer',
      arrivalDate: 'arrivalDate',
      useDate: 'useDate',
      equipmentMoreInfo: 'equipmentMoreInfo',
      equipmentInfo: 'equipmentInfo',
      normalConsumeChangeCutter: 'normalConsumeChangeCutter',
      abnormalConsumeChangeCutter: 'abnormalConsumeChangeCutter'
    },
    validate: {
      placeholderForcutterCode: 'please entry cutterCode',
      placeholderForcutter: 'please entry cutter',
      placeholderForcutterName: 'please entry cutterName',
      placeholderForCl: 'please entry cutterLocation',
      placeholderForSpindle: 'please entry spindle',
      planExpendNum: 'planExpendNum'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      cutterCode: '刀具编码',
      cutter: '刀具',
      cutterName: '刀具名称',
      cutterBoundList: '刀具绑定列表',
      cutterBound: '刀具绑定',
      cl: '刀位',
      spindle: '主轴',
      state: '刀具状态',
      toolBatchNum: '刀具批次',
      totalEquipment: '设备总数', 
      equipmentCode: '设备编码',
      equipmentName: '设备名称',
      equipmentTypeName: '设备类型',
      equipmentModel: '设备型号',
      equipmentSerialNum: '设备出厂编号',
      manufacturer: '生产厂家',
      arrivalDate: '设备到厂日期',
      useDate: '设备编码',
      equipmentMoreInfo: '设备扩展信息',
      equipmentInfo: '设备信息',
      normalConsumeChangeCutter: '正常消耗换刀',
      abnormalConsumeChangeCutter: '非正常消耗换刀',
      planExpendNum: '预计消耗次数'

    },
    validate: {
      placeholderForcutterCode: '请输入刀具编码',
      placeholderForcutterName: '请输入刀具名称',
      placeholderForCl: '请输入刀位',
      placeholderForcutter: '请选择刀具',
      placeholderForSpindle: '请输入主轴'
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

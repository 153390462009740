module.exports = {
  en_US: {
    label: {
      enterprise_code: 'enterpriseCode',
      enterprise_name: 'enterpriseName',
      upper_node: 'UpperNode',
      level_node: 'LevelNode',
      code: 'Code',
      name: 'Name',
      levelInfo: 'This level Info',
      Information: 'Subordinate Info',
      addEquipment: 'addEquipment',
      selectEquipment: 'selectEquipment',
      createDate: 'createDate',
      updateDate: 'updateDate',
      Enterprise: 'Enterprise',
      Site: 'Site',
      Area: 'Area',
      Cell: 'Cell',
      Unit: 'Unit',
      SiteExtend: 'SiteExtend',
      AreaExtend: 'AreaExtend',
      CellExtend: 'CellExtend',
      enterpriseExtend: 'enterpriseExtend',
      notDelete: 'Child elements exist and cannot be deleted',
      plantModel: 'PlantModel',
      showExtend: 'MoreDetails'
      
    },
    input: {
      enterprise_code: 'please entry the enterpriseCode',
      enterprise_name: 'please entry the enterpriseName',
      enterprise: 'please entry ',
      areaName: 'please choose area',
      cellName: 'please choose cell'
    }
  },

  zh_CN: {
    label: {
      enterprise_code: '集团代码',
      enterprise_name: '集团名称',
      upper_node: '上级节点',
      code: '编码',
      name: '名称',
      levelInfo: '本级信息',
      Information: '下级信息',
      level_node: '本级信息',
      addEquipment: '添加设备',
      selectEquipment: '选择设备',
      createDate: '创建时间',
      updateDate: '修改时间',
      Enterprise: '集团',
      Site: '工厂',
      Area: '车间',
      Cell: '产线',
      Unit: '设备',
      SiteExtend: '工厂扩展',
      AreaExtend: '车间扩展',
      CellExtend: '产线扩展',
      enterpriseExtend: '集团扩展',
      notDelete: '存在子元素，不能删除',
      plantModel: '工厂模型',
      showExtend: '更多详情'
    },
    input: {
      enterprise_code: '请输入集团代码',
      enterprise_name: '请输入集团名称',
      input: '请输入',
      areaName: '请选择车间',
      cellName: '请选择产线'
    }
  }
};

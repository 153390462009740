/*
 * @Author: Andy
 * @Date: 2019-08-27 14:11:21
 * @LastEditors: Andy
 * @LastEditTime: 2019-08-27 14:18:16
 */
module.exports = {
  en_US: {
    confirm: {
      confirmDelete: 'Confirm to delete the [{name}] ?',
      confirmDownload: 'Confirm to download the [{name}] ?'
    },
    notify: {
      success: '{action} successfully',
      fail: '{action} failed',
      createSuccess: 'Create successfully',
      createFail: 'Create failed',
      modifySuccess: 'Modify successfully',
      modifyFail: 'Modify failed',
      deleteSuccess: 'Delete successfully',
      deleteFail: 'Delete failed',
      submitSuccess: 'Submit successfully',
      submitFail: 'Submit failed',
      saveSuccess: 'Save successfully',
      saveFail: 'Save failed'
    },
    btn: {
      create: 'Create',
      modify: 'Modify',
      delete: 'Delete',
      search: 'Search',
      submit: 'Submit',
      advanced: 'Advanced',
      save: 'Save',
      detail: 'Detail',
      import: 'Import',
      export: 'Export',
      extend: 'Extend',
      extendedFields: 'Extended fields',
      back: 'Back',
      clone: 'Clone',
      confirm: 'Confirm',
      cancel: 'Cancel',
      close: 'Close',
      more: 'More',
      previousStep: 'Previous step',
      nextStep: 'Next step'
    },
    label: {
      operation: 'Operation',
      now: 'Now',
      item: 'Item',
      record: 'Record',
      total: 'Total'
    },
    judge: {
      yes: 'Yes',
      no: 'No'
    }
  },
  zh_CN: {
    confirm: {
      confirmDelete: '确认删除[{name}] ?',
      confirmDownload: '确认下载[{name}] ?'
    },
    notify: {
      success: '{action}成功',
      fail: '{action}失败',
      createSuccess: '新增成功',
      createFail: '新增失败',
      modifySuccess: '修改成功',
      modifyFail: '修改失败',
      deleteSuccess: '删除成功',
      deleteFail: '删除失败',
      submitSuccess: '提交成功',
      submitFail: '提交失败',
      saveSuccess: '保存成功',
      saveFail: '保存失败'
    },
    btn: {
      create: '新增',
      modify: '修改',
      delete: '删除',
      search: '查询',
      submit: '提交',
      advanced: '高级查询',
      save: '保存',
      detail: '详情',
      import: '导入',
      export: '导出',
      extend: '扩展',
      extendedFields: '扩展字段',
      back: '返回',
      clone: '复制',
      confirm: '确定',
      cancel: '取消',
      close: '关闭',
      more: '更多',
      previousStep: '上一步',
      nextStep: '下一步'
    },
    label: {
      operation: '操作',
      now: '当前',
      item: '条',
      record: '记录',
      total: '共'
    },
    judge: {
      yes: '是',
      no: '否'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      column: 'column',
      parameterCode: 'Code',
      parameterName: 'Name',
      parameterType: 'Type',
      parameterCollection: 'CollectionType',
      parameterUsage: 'Usage',
      standardValue: 'standardValue',
      dataType: 'dataType',
      maxValue: 'maxValue',
      minValue: 'minValue',
      parameter: 'parameter',
      usage: 'usage',
      deleteSuccess: 'deleteSuccess',
      deleteError: 'deleteError',
      maxMinError: 'The minValue is greater than maxValue',
      operate: 'operate'
    },
    input: {
      code: 'Please entry the Code',
      collection: 'Please select the collectionType',
      name: 'Please entry the Name',
      parameterType: 'Please slect the parameterType',
      usage: 'Please select the parameterUsage',
      dataType: 'Please select the dataType',
      maxValue: 'Please entry maxValue',
      minValue: 'Please entry the minValue',
      standardValue: 'Please entry the standardValue',
    },
    action: {
      save: 'save',
      cancel: 'cancel',
      modify: 'modify'
    }
  },

  zh_CN: {
    label: {
      column: '编码',
      parameterCode: '参数编码',
      parameterName: '参数名称',
      parameterType: '参数类型',
      parameterCollection: '采集方式',
      parameterUsage: '用途',
      standardValue: '标准值',
      dataType: '数据类型',
      maxValue: '最大值',
      minValue: '最小值',
      parameter: '工艺参数',
      usage: '用途',
      deleteSuccess: '删除成功',
      deleteError: '删除错误',
      maxMinError: '最小值大于最大值',
      operate: '操作',    
    },
    input: {
      code: '请输入参数编码',
      name: '请输入参数名称',
      collection: '请选择采集方式',
      parameterType: '请选择参数类型',
      usage: '请选择用途',
      dataType: '请选择数据类型',
      maxValue: '请输入最大值',
      minValue: '请输入最小值',
      standardValue: '请输入标准值',
    },
    action: {
      save: '保存',
      cancel: '取消',
      modify: '修改'
    }
  }
};

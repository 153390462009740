export const FIND_MENUS = 'module/FIND_MENUS';

export const FIND_MENUS1 = 'module/FIND_MENUS1';

export const GET_BUTTONS = 'module/GET_BUTTONS';

export const INIT_TABS = 'module/INIT_TABS';

export const ADD_TABS = 'module/ADD_TABS';

export const CHANGE_ACTIVEKEY = 'module/CHANGE_ACTIVEKEY';

export const DEL_TABS = 'module/DEL_TABS';

export const TEST_INIT_TABS = 'module/TEST_INIT_TABS';

export const CLEAR_MENUS = 'module/CLEAR_MENUS';

export const GET_COLLECTION_MENU = 'module/GET_COLLECTION_MENU';

export const REDUCER_MENUS = 'module/REDUCER_MENUS';

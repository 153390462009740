module.exports = {
  en_US: {
    label: {
      uploadForFile: 'Upload',
      creatorIdForFile: 'founder',
      CheckUploadForFile: 'Click to upload',
      codeForFile: 'fileCode',
      fileExtendForFile: 'fileType',
      fileSizeForFile: 'fileSize',
      nameForFile: 'fileName',
      extendNameForFile: 'fileExtendName',
      typeForFile: 'sort',
      pathForFile: 'filePath',
      descForFile: 'FilesDesc',
      uomForFile: 'uom',
      eigenvalueForFile: 'eigenvalue',
      serialNumberProfileForFile: 'Profile',
      createTimeForFile: 'createTime',
      operate: 'operate',
      FileManage: 'File Manage',
      memoForFile: 'memo',
      column: 'column',
      type: 'type',
      nameForCH: 'name (chinese)',
      nameForEN: 'name(english)',
      addSuccess: 'add success',
      addError: 'add error',
      deleteSuccess: 'delete success',
      deleteError: 'delete error',
      downloadSuccess: 'download success',
      downloadError: 'download error'
      
    },
    action: {
      search: 'search',
      export: 'export',
      import: 'import',
      save: 'save',
      cancel: 'cancel',
      modify: 'modify',
      close: 'close'
    },
    validate: {
      placeholderForTypeCode: 'please entry FileTypeCode',
      placeholderForTypeName: 'please entry FileTypeName',
      placeholderForName: 'please entry FileName',
      placeholderForType: 'please entry FileType',
      placeholderForPath: 'please select file path',
      placeholderForFile: 'please upload file',
      placeholderForDesc: 'please entry description',
      placeholderForColumn: 'please entry column',
      placeholderForColumnType: 'please entry type',
      placeholderForEN: 'please entry name(english)',
      placeholderForCH: 'please entry name(chinese)',
      placeholderForeigenvalue: 'please entry eigenvalue',    
      placeholderUom: 'please entry uom',  
    }
  },
  zh_CN: {
    label: {
      uploadForFile: '上传',
      creatorIdForFile: '创建人',
      CheckUploadForFile: '点击上传',
      fileExtendForFile: '文件类型',
      codeForFile: '物料编码',
      fileSizeForFile: '文件大小',
      nameForFile: '文件名称',
      extendNameForFile: '文件拓展名',
      typeForFile: '所属分类',
      pathForFile: '文件地址',
      descForFile: '物料描述',
      uomForFile: '物料单位',
      eigenvalueForFile: '特征值',
      serialNumberProfileForFile: '是否追溯',
      createTimeForFile: '上传时间',
      operate: '操作',
      FileManage: '文件管理',
      memoForFile: '物料备注',
      column: '编码',
      type: '文件类型',
      nameForCH: '名称(中文)',
      nameForEN: '名称(英文)',
      addSuccess: '添加成功',
      addError: '添加失败',
      deleteSuccess: '删除成功',
      deleteError: '删除失败',
      downloadSuccess: '下载成功',
      downloadError: '下载失败'

    },
    action: {
      search: '查找',
      export: '导出',
      import: '导入',
      save: '保存',
      cancel: '取消',
      close: '关闭'
    },
    validate: {
      placeholderForTypeCode: '请输入类型编码',
      placeholderForTypeName: '请输入类型名称',
      placeholderForName: '请输入文件名称',
      placeholderForType: '请选择文件类型',
      placeholderForFile: '请上传文件',
      placeholderForPath: '请选择文件地址',
      placeholderForDesc: '请输入物料描述',
      placeholderForColumn: '请输入编码',
      placeholderForColumnType: '请选择类型',
      placeholderForEN: '请输入名称(英文)',
      placeholderForCH: '请输入名称(中文)',
      placeholderForeigenvalue: '请输入特征值',  
      placeholderUom: '请输入单位编码',    
    }
  }
};

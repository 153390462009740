import { get as _get } from "lodash";
import * as types from "./types";

const initialState = {
  list: {},
  shaoList: {},
  yuanList: {},
  standrenData: [],
  paiData: [],
  KSMData: [],
  allData: {}
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_MEIFEN1:
      return {
        ...state,
        list: action.data
      };
    case types.GET_SHAO:
        return {
          ...state,
          shaoList: action.shaoData
        };
    case types.GET_YUAN:
      return {
        ...state,
        yuanList: action.yuanData
      };
    case types.GET_SHAOSTANDREN:
      return {
        ...state,
        standrenData: action.shaoStandrenData
      };
    case types.GET_PAIHANG:
      return {
        ...state,
        paiData: action.data
      };
    case types.GET_KSM:
      return {
        ...state,
        KSMData: action.data
      };
    case types.GET_ALL:
      return {
        ...state,
        allData: action.allData
      };
    default:
      return state;
  }
};

export const key = "performance";

reducer.reducer = key;

export default reducer;

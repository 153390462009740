export const GET_MEIFEN1 = 'quality/GET_MEIFEN1';

export const GET_SHAO = 'quality/GET_SHAO';

export const GET_SHUI = 'quality/GET_SHUI';

export const GET_YUAN = 'quality/GET_YUAN';

export const GET_SHAOSTANDREN = 'quality/GET_SHAOSTANDREN';

export const GET_YUANSTANDREN = 'quality/GET_YUANSTANDREN';

export const GET_PAIHANG = 'quality/GET_PAIHANG';

export const GET_KSM = 'quality/GET_KSM';

export const GET_ALL = 'quality/GET_ALL';

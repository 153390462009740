import { get as _get } from 'lodash';
import * as types from './types';

const initialState = {
  list: [],
  columns: [],
  total: 0,
  dataTypeList: {},
  parameterTypeList: {},
  parameterUsageList: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_PARAMETERS:
      return {
        ...state,
        list: _get(action.data, 'content'),
        total: _get(action.data, 'totalElements')
      };
    case types.GET_PARAMETER_DATA_TYPE:
      
      return {
        ...state,
        dataTypeList: action.data, 
        
      };
    case types.GET_PARAMETER_TYPE_LIST:
    
      return {
        ...state,
        parameterTypeList: action.data
      };
    case types.GET_PARAMETER_USAGE_LIST:

      return {
        ...state,
        parameterUsageList: action.data,
      };
    default:
      return state;
  }
};

export const key = 'parameter';

reducer.reducer = key;

export default reducer;

module.exports = {
  en_US: {
    label: {
      start: 'starting position',
      end: 'end position',
      zhengze: 'regular expression',
      service: 'services'
    },
    validate: {
      placeholderForservices: 'please select services',
      placeholderForstart: 'please entry starting position',
      placeholderForend: 'please entry end position',
      placeholderForzhengze: 'please entry regular expression',
    }
  },

  zh_CN: {
    label: {
      start: '起始位置',
      end: '截至位置',
      zhengze: '正则表达式',
      service: '服务'
    },
    validate: {
      placeholderForservices: '请选择服务',
      placeholderForstart: '请输入开始位置',
      placeholderForend: '请输入结束位置',
      placeholderForzhengze: '请输入正则表达式',
    }
  }
};

module.exports = {
  en_US: {
    label: {
      inspectContentCode: 'inspectContentCode',
      inspectContentName: 'inspectContentName',
    },
    validate: {
      placeholderForinspectContentCode: 'please entry inspectContentCode',
      placeholderForinspectContentName: 'please entry inspectContentName',
    }
  },

  zh_CN: {
    label: {
      inspectContentCode: '点检内容编码',
      inspectContentName: '点检内容名称',
    },
    validate: {
      placeholderForinspectContentCode: '请输入点检内容编码',
      placeholderForinspectContentName: '请输入点检内容名称',
    }
  }
};

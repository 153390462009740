const version = require('../../package.json');
const login = require('./login').en_US;
const message = require('./message').en_US;
const menu = require('./sysManager/menu').en_US;
const location = require('./sysManager/location').en_US;
const user = require('./sysManager/user').en_US;
const role = require('./sysManager/role').en_US;
const equipment = require('./sysManager/equipment').en_US;
const equipmentType = require('./sysManager/equipmentType').en_US;
const materialType = require('./sysManager/materialType').en_US;
const materialUom = require('./sysManager/materialUom').en_US;
const extend = require('./components/extends').en_US;
const bom = require('./sysManager/bom').en_US;
const parameter = require('./sysManager/parameter').en_US;
const material = require('./sysManager/material').en_US;
const fileType = require('./sysManager/fileType').en_US;
const file = require('./sysManager/file').en_US;
const log = require('./sysManager/log').en_US;
const operation = require('./sysManager/operation').en_US;
const schedule = require('./sysManager/schedule').en_US;
const schedulement = require('./sysManager/schedulement').en_US;
const route = require('./sysManager/route').en_US;
const workOrder = require('./sysManager/workOrder').en_US;
const timer = require('./sysManager/timer').en_US;
const ProductionPlan = require('./sysManager/ProductionPlan').en_US;
const sparePartInfo = require('./sysManager/sparePart/sparePartInfo').en_US;
const sparePartAnalysis = require('./sysManager/sparePart/sparePartAnalysis').en_US;
const sparePartType = require('./sysManager/sparePart/sparePartType').en_US;
const sparePartWarehouse = require('./sysManager/sparePart/sparePartWarehouse').en_US;
const sparePartUnit = require('./sysManager/sparePart/sparePartUnit').en_US;
const sparePartInStorage = require('./sysManager/sparePart/sparePartInStorage').en_US;
const SparePartInventory = require('./sysManager/sparePart/SparePartInventory').en_US;
const equipmentFault = require('./sysManager/EquipmentManage/equipmentFault').en_US;
const maintenanceProject = require('./sysManager/EquipmentManage/equipmentMaintain/maintenanceProject').en_US;
const maintenanceContent = require('./sysManager/EquipmentManage/equipmentMaintain/maintenanceContent').en_US;
const lubricationWay = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationWay').en_US;
const maintenancePlan = require('./sysManager/EquipmentManage/equipmentMaintain/maintenancePlan').en_US;
const lubricationProject = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationProject').en_US;
const lubricationContent = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationContent').en_US;
const lubricationPlan = require('./sysManager/EquipmentManage/equipmentLubrication/lubricationPlan').en_US;
const inspectItemProject = require('./sysManager/EquipmentManage/equipmentInspectItem/inspectItemProject').en_US;
const inspectItemContent = require('./sysManager/EquipmentManage/equipmentInspectItem/inspectItemContent').en_US;
const inspectItemPlan = require('./sysManager/EquipmentManage/equipmentInspectItem/inspectItemPlan').en_US;
const lubType = require('./sysManager/lub/lubType').en_US;
const lubInfo = require('./sysManager/lub/lubInfo').en_US;
const lubInventory = require('./sysManager/lub/lubInventory').en_US;
const maintenanceCrew = require('./sysManager/EquipmentManage/maintenanceCrew').en_US;
const equipmentMaintenance = require('./sysManager/EquipmentManage/equipmentMaintenance').en_US;
const task = require('./sysManager/task').en_US;
const department = require('./sysManager/department').en_US;
const cutterInventory = require('./sysManager/cutter/inventory').en_US;
const cutterInfo = require('./sysManager/cutter/info').en_US;
const equipmentCutter = require('./sysManager/cutter/equipmentCutter').en_US;
const global = require('./sysManager/global').en_US;

module.exports = {
  app: {
    footer: 'Designed By H VISIONS',
    logout: 'Sign Out',
    home: 'home',
    personal_information: 'information',
    modify_password: 'modify password'
  },
  login,
  message,
  menu,
  user,
  role,
  equipment,
  extend,
  material,
  bom,
  equipmentType,
  materialType,
  materialUom,
  location,
  parameter,
  fileType,
  file,
  log,
  operation,
  schedule,
  schedulement,
  route,
  workOrder,
  ProductionPlan,
  sparePartInfo,
  sparePartType,
  sparePartWarehouse,
  sparePartAnalysis,
  sparePartUnit,
  timer,
  SparePartInventory,
  equipmentFault,
  maintenanceProject,
  maintenanceContent,
  maintenancePlan,
  lubricationProject,
  lubricationContent,
  lubricationPlan,
  inspectItemProject,
  inspectItemContent,
  inspectItemPlan,
  lubType,
  lubInfo,
  lubInventory,
  sparePartInStorage,
  maintenanceCrew,
  equipmentMaintenance,
  task,
  lubricationWay,
  department,
  cutterInventory,
  cutterInfo,
  equipmentCutter,
  global
};

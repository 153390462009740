module.exports = {
  en_US: {
    label: {
      lubCode: 'lubCode',
      lubName: 'lubName',
      batchNumber: 'batchNumber',
      company: 'company',
      supplier: 'supplier',
      company1: 'company',
      lubType: 'lubType',
      unitPrice: 'unitPrice',
      cargoLocation: 'cargoLocation',
      cargoMax: 'cargoMax',
      cargoMin: 'cargoMin',
      lubTable: 'lubTable',
      lubCount: 'lubCount',
      remarks: 'remarks',
      titleAdd: 'add > lub',
      titleEdit: 'edit > lub',
      photo: 'photo'
    },
    validate: {
      placeholderForlubCode: 'please entry lubCode',
      placeholderForlubName: 'please entry lubName',
      placeholderForbatchNumber: 'please entry batchNumber',
      placeholderForcompany: 'please entry company',
      placeholderForsupplier: 'please entry supplier',
      placeholderForcargoLocation: 'please entry cargoLocation',
      placeholderForcargoMax: 'please entry cargoMax',
      placeholderForlubType: 'please entry lubType',
      placeholderForcargoMin: 'please entry cargoMin',
      placeholderForunitPrice: 'please entry unitPrice',
      placeholderForremarks: 'please entry remarks'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      lubCode: '油品编号',
      lubName: '油品名称',
      batchNumber: '油品批次号',
      company: '油品单位',
      company1: '油品单位',
      supplier: '油品供应商',
      lubType: '油品类型',
      cargoLocation: '油品库房',
      unitPrice: '油品单价',
      cargoMax: '油品最大库存',
      cargoMin: '油品最小库存',
      lubTable: '油品列表',
      lubCount: '油品数量',
      remarks: '备注',
      titleAdd: '新增 > 编辑油品',
      titleEdit: '详情 > 编辑油品',
      photo: '油品图片'
    },
    validate: {
      placeholderForlubCode: '请输入油品编码',
      placeholderForlubName: '请输入油品名称',
      placeholderForbatchNumber: '请输入油品批次号',
      placeholderForcompany: '请选择油品单位',
      placeholderForsupplier: '请输入油品供应商',
      placeholderForlubType: '请选择油品类型',
      placeholderForcargoLocation: '请选择油品库房',
      placeholderForcargoMax: '请输入油品最小库存',
      placeholderForcargoMin: '请输入油品最大库存',
      placeholderForunitPrice: '请输入油品单价',
      placeholderForremarks: '请输入备注',
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

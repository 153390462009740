module.exports = {
  en_US: {
    label: {
      spareNumber: 'spareNumber ',
      spareInventory: 'spareInventoryTable',
      sparePart: 'sparePart',
      storehouse: 'storehouse',
      sparePartType: 'sparePartType',
      sparePartNumber: 'sparePartNumber',
    },
    validate: {
      placeholderFortypeCode: 'please entry spareNumber ',
      placeholderForstorehouse: 'please entry storehouse',
      placeholderForsparePartType: 'please entry sparePartType',
      placeholderForsparePartNumber: 'please entry sparePartNumber'
    },
    btn: {
      addNode: 'New child node'
    }
  },

  zh_CN: {
    label: {
      spareNumber: '备件数量',
      spareInventory: '备件库存列表',
      sparePart: '备件',
      storehouse: '备件库房',
      sparePartType: '备件类型',
      sparePartNumber: '备件数量',
    },
    validate: {
      placeholderForsparePart: '请选择备件',
      placeholderForstorehouse: '请选择备件库房',
      placeholderForsparePartType: '请选择备件类型',
      placeholderForsparePartNumber: '请输入备件数量'
    },
    btn: {
      addNode: '新增子节点'
    }
  }
};

module.exports = {
  en_US: {
    label: {
      shelveCode: 'shelveCode ',
      shelveName: 'shelveName',
      shelveTable: 'spareShelveTable',
      shelveUserId: 'shelveUser',
      shelvePlace: 'shelvePlace'
    },
    validate: {
      placeholderForshelveUserId: 'please select shelveUser ',
      placeholderForshelvePlace: 'please entry shelvePlace ',
      placeholderForshelveCode: 'please entry shelveCode ',
      placeholderForshelveName: 'please entry shelveName',
      placeholderForshelve: 'please entry shelve',
    }
  },

  zh_CN: {
    label: {
      shelveCode: '备件库房编码',
      shelveName: '备件库房名称',
      shelveTable: '备件库房列表',
      shelveUserId: '库房管理人员',
      shelvePlace: '库房地点'
    },
    validate: {
      placeholderForshelveCode: '请输入备件库房编码',
      placeholderForshelve: '请输入备件库房',
      placeholderForshelveName: '请输入备件库房名称',
      placeholderForshelveUserId: '请选择库房管理人员',
      placeholderForshelvePlace: '请输入备件库房地点',
    }
  }
};

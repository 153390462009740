import * as types from './types';
const initialState = {
  crewList: [],
  cellList: [],
  shiftList: [],
  scheduleList: [],
  singlePlanList: {},
  singlescheduleList: [{ crewShift: [] }]
};
const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_AREALIST:
      return {
        ...state,
        areaList: action.data
      };
    case types.GET_CELLLIST:
      return {
        ...state,
        cellList: action.data
      }; 
    case types.GET_SHIFTLIST:
      return {
        ...state,
        shiftList: action.data
      };    
    case types.GET_CREWLIST:
      return {
        ...state,
        crewList: action.data
      };   
    case types.GET_SCHEDULELIST:
      return {
        ...state,
        scheduleList: action.data
      };        
    case types.GET_SINGLEPALN:
      return {
        ...state,
        singlePlanList: action.data
      };  
    case types.GET_SINGLESCHEDULELIST:
      return {
        ...state,
        singlescheduleList: action.data
      };
    default:
      return state;
  }
};

export const key = 'schedulement';

reducer.reducer = key;

export default reducer;

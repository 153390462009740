/*
 * @Author: Otway
 * @Date: 2019-08-19 13:39:06
 * @LastEditors: Otway
 * @LastEditTime: 2019-08-19 14:40:55
 * @copyright: h-visions
 */
import { get as _get } from 'lodash';
import * as types from './types';

const initialState = {
  dianjia: {},
  wcNumbers: [],
  data: []
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_OPERATION_LIST:
      return {
        ...state,
        dianjia: action.data
      }; 
    case types.GET_EXTENDS_COLUMNS:
      return {
        ...state,
        wcNumbers: action.data
      }; 
    case types.GET_OPEATION_DETAIL_LIST:
      return {
        ...state,
        data: action.data
      }; 
    default:
      return state;
  }
};

export const key = 'offpeakanalysis';

reducer.reducer = key;

export default reducer;

module.exports = {
  en_US: {
    validate: {
      placeholderForAccount: 'please entry account',
      placeholderForDepartmentName: 'please select department',
      placeholderForName: 'please entry name',
      placeholderForEmail: 'please entry email',
      placeholderForPhone: 'please entry phone',
      placeholderForBirthday: 'please entry birthday',
      placeholderForDescription: 'please entry description',
      placeholderForColumn: 'please entry code',
      placeholderForType: 'please choose type',
      placeholderForCH: 'please entry name (chinese)',
      placeholderForEN: 'please entry name (english)',
      placeholderForTureEmail: 'please entry true email',
      placeholderForTurePhone: 'please entry true phone',
      placeholderForExtend: 'please entry {key}',
      role_choices: 'Role Choices',
      placeholderForOldPassword: 'please entry old password',
      placeholderForNewPassword: 'please entry new password',
      tipForPassword: 'two password inconsistencies'
    },
    label: {
      email: 'email',
      role: 'role',
      birthday: 'birthday',
      phone: 'phone',
      sex: 'sex',
      description: 'description',
      number: 'number',
      name: 'name',
      account: 'account',
      operate: 'operate',
      addUser: 'add user',
      modifyUser: 'modify user',
      userManager: 'user Manage',
      column: 'code',
      type: 'type',
      nameForCH: 'name(chinese)',
      nameForEN: 'name(english)',
      deleteSuccess: 'delete success',
      deleteError: 'delete error',
      modifySuccess: 'modify suucess',
      modifyError: 'modify error',
      addSuccess: 'add success',
      addError: 'add error',
      man: 'man',
      woman: 'woman',
      oldPassWord: 'oldpassword',
      newPassWord: 'newpassword',
      departmentName: 'departmentName',
      department: 'department'
    },
    action: {
      newAdd: 'new add',
      extendField: 'extend filed',
      modify: 'modify',
      roleConfig: 'role',
      delete: 'delete',
      save: 'save',
      cancel: 'cancel',
      resetSucess: 'resetSucess',
      resetError: 'resetError',
      modifyPassWord: 'modify password'
    }
  },
  zh_CN: {
    validate: {
      placeholderForAccount: '请输入账号',
      placeholderForDepartmentName: '请选择部门',
      placeholderForName: '请输入姓名',
      placeholderForEmail: '请输入邮箱',
      placeholderForPhone: '请输入手机号',
      placeholderForBirthday: '请选择生日',
      placeholderForDescription: '请输入描述',
      placeholderForColumn: '请输入编码',
      placeholderForType: '请选择类型',
      placeholderForCH: '请输入名称(中文)',
      placeholderForEN: '请输入名称(英文)',
      placeholderForTureEmail: '请输入正确的邮箱',
      placeholderForTurePhone: '请输入正确的手机号',
      placeholderForExtend: '请输入{key}',
      role_choices: '角色选择',
      placeholderForOldPassword: '请输入旧密码',
      placeholderForNewPassword: '请输入新密码',
      tipForPassword: '两次密码不一样'
    },
    label: {
      number: '序号',
      name: '姓名',
      account: '账号',
      email: '邮箱',
      role: '角色',
      birthday: '生日',
      phone: '手机号',
      sex: '性别',
      description: '描述',
      operate: '操作',
      addUser: '新增用户',
      modifyUser: '修改用户',
      userManager: '用户管理',
      column: '编码',
      type: '类型',
      nameForCH: '名称(中文)',
      nameForEN: '名称(英文)',
      deleteSuccess: '删除成功',
      deleteError: '删除失败',
      modifySuccess: '修改成功',
      modifyError: '修改失败',
      addSuccess: '增加成功',
      addError: '增加失败',
      man: '男',
      woman: '女',
      oldPassWord: '旧密码',
      newPassWord: '新密码',
      departmentName: '部门名称',
      department: '部门'
    },
    action: {
      newAdd: '新增',
      extendField: '拓展字段',
      modify: '修改',
      roleConfig: '权限配置',
      delete: '删除',
      save: '保存',
      cancel: '取消',
      resetSucess: '重置成功',
      resetError: '重置失败',
      modifyPassWord: '修改密码'
    }
  }
};

import * as types from './types';
import { push as pushState } from 'react-router-redux';
import i18n from '~/i18n';
import {
  saveToken,
  setAuthData,
  clear,
  setLocale
} from '~/util/session';

import Service from '~/util/request';

const _service = new Service();

export const login = value => async ({ api, dispatch }) => {
  try {
    const data = await api.post('/login', {}, { params: value });
    const defaultAPI = data.sysModuleDTO ? data.sysModuleDTO.urlAddress : '/';
    saveToken(data.token);
    // 登录成功发送一个异步，登录信息查询界面使用
    loginRecordFn(data.userName, data.id);

    setAuthData(data);
    dispatch({
      type: types.LOGIN_SUCCESS,
      data
    });
    dispatch(pushState(defaultAPI));
  } catch (err) {
    throw new Error(err);
  }
};


const loginRecordFn = async (userName, userId) => {

  const userRoleData = await _service.get(`/auth/role/getRoleListByUserId/${userId}`);
  let userAccount = '';
  if (userRoleData && userRoleData.length) {
    userAccount = userRoleData[0].description
  }

  const _userName = encodeURIComponent(userName);
  const _userGroup = encodeURIComponent(userAccount)

 

  await _service.post(`http://${__API_HOST__}:8090/CloudWeb/pageInfo.sp?method=saveLoginInfo&userName=${_userName}&userGroup=${_userGroup}`, {
    userName: _userName,
    userGroup: _userGroup,
  });


};




export const logout = () => ({ dispatch }) => {
  clear();
  dispatch(pushState('/login'));
};

export const changeLocale = locale => () => {
  setLocale(locale);
  i18n(locale);
};

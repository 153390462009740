import { get as _get } from 'lodash';
import * as types from './types';

const initialState = {
  getScreenType: true
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_SCREEN_TYPE:
      return {
        ...state,
        getScreenType: action.data
      };
    default: 
      return state;
  }
};

export const key = 'home';

reducer.reducer = key;

export default reducer;

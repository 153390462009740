import { get as _get } from 'lodash';
import * as types from './types';

const initialState = {
  list1: [],
  list2: [],
  list3: [],
  prodProductTotalInfo: { // 生产简报Obj
    clinkerOutput: 100, // 熟料产量
    rawCoalConsumption: 120, // 实物消耗
    dailySale: 10, // 熟料销售量
    stdCoalConsumption: 50, // 标准煤耗
    kilnOutputHour: 140, // 台产
    comprehensivePowerConsumption: 70, // 综合熟料电耗
    tonneClinkerPower: 100 // 吨熟料发电量
  },
  prodStopInfo: [
    {
      wcName: '原料粉磨',
      faultStop: 2,
      planStop: 5
    },
    {
      wcName: '烧成系统',
      faultStop: 2,
      planStop: 4
    },
    {
      wcName: '煤粉制备',
      faultStop: 2,
      planStop: 2.5
    }
  ],
  prodRawMillRatio: {
    caCO3Ratio: 62.68,
    fe2O3SiO2Ratio: 14.74,
    siO2Al2O3Ratio: 12.83,
    shaleRatio: 9.75,
    rawMillHourOutput: 80
  },
  cementMillRatio: {
    caCO3Ratio: 62.68,
    clinkerRatio: 14.74,
    coalSlagRatio: 12.83,
    desulfurizationGypsumRatio: 9.75,
    flyAshRatio: 80,
    ganGueRatio: 45
  },
  prodRatesPeriod: [{
    ratesTypeName: '尖峰时段',
    rawMillRate: 85,
    kilnRunRate: 10,
    coalMillRunRate: 20
  }, {
    ratesTypeName: '高峰时段',
    rawMillRate: 85,
    kilnRunRate: 10,
    coalMillRunRate: 20
  }, {
    ratesTypeName: '低谷时段',
    rawMillRate: 85,
    kilnRunRate: 10,
    coalMillRunRate: 20
  }, {
    ratesTypeName: '平时段',
    rawMillRate: 85,
    kilnRunRate: 10,
    coalMillRunRate: 20
  }],
  prodPowerGenSatistics: {
    wHRGenGen: 11,
    wHRPowerOnGrid: 11,
    wHRL2PowerAVG: 11,
    wHRL2RunHour: 12,
    tonneClinkerWHRPowerGen: 11,
    kilnRunRate: 12
  },
  prodMonthYearByProcess: [{
    title: '产量',
    time: '2019',
    kiln: 41335.61,
    rawMil: 60259,
    coalMi: 7091.98,
    cement: 0
  }, {
    title: '运转时间',
    time: '2019',
    kiln: 413.61,
    rawMil: 259,
    coalMi: 91.98,
    cement: 0
  }, {
    title: '台产',
    time: '2019',
    kiln: 41335.61,
    rawMil: 60259,
    coalMi: 7091.98,
    cement: 0
  }, {
    title: '工序电耗',
    time: '2019',
    kiln: 413.61,
    rawMil: 259,
    coalMi: 91.98,
    cement: 0
  }, {
    title: '运转率',
    time: '2019',
    kiln: 41335.61,
    rawMil: 60259,
    coalMi: 7091.98,
    cement: 0
  }, {
    title: '可靠性系数',
    time: '2019',
    kiln: 413.61,
    rawMil: 259,
    coalMi: 91.98,
    cement: 0
  }, {
    title: '实物煤耗',
    time: '2019',
    kiln: 41335.61,
    rawMil: 60259,
    coalMi: 7091.98,
    cement: 0
  }, {
    title: '标煤耗',
    time: '2019',
    kiln: 413.61,
    rawMil: 259,
    coalMi: 91.98,
    cement: 0
  }]
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_SHFITREPORTKILN:
      return {
        ...state,
        list1: action.data || []
      };
    case types.GET_SHFITREPORTRAWMILL:
      return {
        ...state,
        list2: action.data || []
      };
    case types.GET_SHFITREPORTCOALMILL:
      return {
        ...state,
        list3: action.data || []
      };
    case types.GET_PROPMONITORCHART: 
      return {
        ...state,
        middleChart: action.data || []
      };
      case types.GET_PROPMONITORCHART2: 
      return {
        ...state,
        middleChart2: action.data || []
      };
    case types.GET_PRODPRODUCTOTALINFO:
      return {
        ...state,
        prodProductTotalInfo: action.data
      };
    case types.GET_PRODSTOPINFO:
        return {
          ...state,
          prodStopInfo: action.data
        };  
    case types.GET_PRODRAWMILLRATIO_BY_MONTHYEAR:
        return {
          ...state,
          prodRawMillRatio: action.data
        };
    case types.GET_CEMENTMILLRATIO_BY_MONTHYEAR:
        return {
          ...state,
          cementMillRatio: action.data
        };
    case types.GET_PRORATESPERIOD_BY_MONTHYAER:
        return {
          ...state,
          prodRatesPeriod: action.data
        };       
    case types.GET_PRODPOWERGENSATISTICS_BY_MONTHYEAR:
        return {
          ...state,
          prodPowerGenSatistics: action.data
        };       
    case types.GET_PRODMONTHYEAR_BY_PROCESSCHART:
        return {
          ...state,
          prodMonthYearByProcess: action.data
        };     
    case types.GETTONNEELECTRICOAST:
        return {
          ...state,
          tonneElectricCost: action.data || []
        };       
    default:
      return state;
  }
};

export const key = 'productionReport';

reducer.reducer = key;

export default reducer;

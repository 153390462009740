import * as types from './types';

const initialState = {
    reportData: []
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REPORT_DATA:
            return {
                ...state,
                reportData: action.data
            }
        default:
            return state;
    }
}

export const key = 'measureReport'

reducer.reducer = key;

export default reducer;
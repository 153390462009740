import * as types from './types';

const initialState = {
  data: {}
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data
      };
    default:
      return state;
  }
};

export const key = 'session';

reducer.reducer = key;

export default reducer;

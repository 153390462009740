module.exports = {
  en_US: {
    label: {
      groupForMaterial: 'materialsGroup',
      codeForMaterial: 'materialsCode',
      nameForMaterial: 'materialsName',
      typeForMaterial: 'materialsType',
      descForMaterial: 'materialsDesc',
      uomForMaterial: 'uom',
      eigenvalueForMaterial: 'eigenvalue',
      serialNumberProfileForMaterial: 'Profile',
      createTimeForMaterial: 'createTime',
      operate: 'operate',
      materialManage: 'material Manage',
      memoForMaterial: 'memo',
      column: 'column',
      type: 'type',
      nameForCH: 'name (chinese)',
      nameForEN: 'name(english)',
      addSuccess: 'add success',
      addError: 'add error',
      deleteSuccess: 'delete success',
      deleteError: 'delete error',
      yes: 'yes',
      no: 'no'
    },
    action: {
      search: 'search',
      export: 'export',
      import: 'import',
      save: 'save',
      cancel: 'cancel',
      modify: 'modify',
      extendField: 'extend',
      newAdd: 'new add'
    },
    validate: {
      placeholderForColumnGroup: 'placeholderForColumnGroup',
      placeholderForCode: 'please entry materialCode',
      placeholderForName: 'please entry materialName',
      placeholderForType: 'please entry materialType',
      placeholderForDesc: 'please entry description',
      placeholderForColumn: 'please entry column',
      placeholderForColumnType: 'please select type',
      placeholderForColumnUom: 'please select uom',
      placeholderForEN: 'please entry name(english)',
      placeholderForCH: 'please entry name(chinese)',
      placeholderForeigenvalue: 'please entry eigenvalue',
      placeholderUom: 'please entry uom'
    },
    type: {
      semi_finished: 'semi_finished',
      finished: 'finished'
    }
  },
  zh_CN: {
    label: {
      groupForMaterial: '物料分组',
      codeForMaterial: '物料编码',
      nameForMaterial: '物料名称',
      typeForMaterial: '物料类型',
      descForMaterial: '物料描述',
      uomForMaterial: '物料单位',
      eigenvalueForMaterial: '特征值',
      serialNumberProfileForMaterial: '是否追溯',
      createTimeForMaterial: '创建时间',
      operate: '操作',
      materialManage: '新增',
      memoForMaterial: '物料备注',
      column: '编码',
      type: '类型',
      nameForCH: '名称(中文)',
      nameForEN: '名称(英文)',
      addSuccess: '添加成功',
      addError: '添加失败',
      deleteSuccess: '删除成功',
      deleteError: '删除失败',
      yes: '是',
      no: '否'
    },
    action: {
      search: '查找',
      export: '导出',
      import: '导入',
      save: '保存',
      cancel: '取消',
      modify: '修改',
      extendField: '拓展字段',
      newAdd: '新增'
    },
    validate: {
      placeholderForColumnGroup: '请输入物料分组',
      placeholderForCode: '请输入物料编码',
      placeholderForName: '请输入物料名称',
      placeholderForType: '请输入物料类型',
      placeholderForDesc: '请输入物料描述',
      placeholderForColumn: '请输入编码',
      placeholderForColumnType: '请选择物料类型',
      placeholderForColumnUom: '请选择物料单位',
      placeholderForEN: '请输入名称(英文)!',
      placeholderForCH: '请输入名称(中文)!',
      placeholderForeigenvalue: '请输入特征值!',
      placeholderUom: '请输入单位编码'
    },
    type: {
      semi_finished: '半成品',
      finished: '成品'
    }
  }
};
